<template>
  <title>Resultaat | Dividenduitkering</title>
  <div class="container-fluid cpbm">
    <div class="row">
      <div class="col-12" :class="{'cptl cpm': !windowCheckerData.isMobileScreen, 'cptm cpxm' : windowCheckerData.isMobileScreen}">

        <div class="du-title-block d-inline-block mb-md-3">
          <div class="du-title-text h-100 d-flex align-items-center">
<!--            <h1>{{test[0].resultName }}</h1>-->
            <h1>John Doe</h1>
            <h1>Download PDF</h1>
          </div>
        </div>

        <div class="du-content-fulltext">
          <p>Op basis van de ingevoerde gegevens is het toetsingsvermogen <span class="du-result-mark">NEGATIEF</span>. De door de algemene vergadering vastgestelde uitkering is hoger dan het vrij uitkeerbaar (eigen) vermogen. <br><br>
            Op basis van artikel 216 lid 1 Burgerlijk Wetboek 2 en de cijfers in de gebruikte jaarrekening kan er door de algemene vergadering bij de voorgestelde uitkering GEEN rechtsgeldig besluit genomen worden tot het vaststellen van deze uitkering. Het uitvoeren van een betalingscapaciteitstoets cq het verdergaand invullen van de vragenlijst met betrekking tot deze voorgestelde uitkering heeft dus geen nut. <br><br>
            Zodra u op onderstaande knop drukt zal er een PDF worden gegenereerd met de uitkomsten van deze uitkeringstoets. Deze pdf wordt eveneens naar uw e-mail adres gestuurd. Na het genereren van deze PDF is deze online toets niet meer benaderbaar.
          </p>
        </div>

        <div class="du-question-controls du-controls-content-smallmargin d-flex">
          <div class="w-100">
            <div class="du-question-control-left d-inline-block" :class="{'col-12':windowCheckerData.isMobileScreenXS,'cmbs': windowCheckerData.isMobileScreen, 'cpes': !windowCheckerData.isMobileScreenXS}">

              <div class="du-question-control-left d-inline-block">
                <router-link to="/nieuwe-aanvraag">
                  <button class="du-button">DOWNLOAD PDF
                    <i class="fa-solid fa-chevron-right thick-icon"></i>
                  </button>
                </router-link>
              </div>

              <div class="du-question-control-right d-inline-block" :class="{'col-12':windowCheckerData.isMobileScreenXS,}">
                <button class="du-button du-button-transparent">AFRONDEN
                  <i class="fa-solid fa-chevron-right thick-icon"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <WindowChecker @resize-window="windowChecker"></WindowChecker>
</template>

<script>
import Navigation from "@/components/Navigation.vue";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';
import modal from "bootstrap/js/src/modal";
import { useUserStore } from '@/store/user'
import { storeToRefs } from 'pinia'
import WindowChecker from "@/components/WindowChecker.vue";
import {useTheme} from "@/components/utils/theme";

let userStore
let userVar
let loggedIn

export default {
  name: "QuestionView",
  computed: {
    modal() {
      return modal
    }
  },
  components: {WindowChecker, Navigation, Header, Footer, Loading},

  setup() {
    userStore = useUserStore()
    const {user, isLoggedIn} = storeToRefs(userStore)
    userVar = user.value
    loggedIn = isLoggedIn.value
    const { isDarkPage } = useTheme()

    return {user, isLoggedIn, isDarkPage}
  },
  methods: {
    windowChecker(data) {
      this.windowCheckerData = data;
    },
  },
  data() {
    return {
      windowCheckerData: {
        isMobileScreenXS: false,
        isMobileScreenS: false,
        isMobileScreen: false,
        isTabletScreen: false
      },
      test: "string",
    }
  },


  async created() {
    let flArray =
    {
      selectAll: "true",
      from: "results",
      where: {
        st1: "results.resultNumber = '" + this.$route.query.id + "'",
      },
      testQuery: "false",
    }

    // POST request using fetch with async/await
    const requestOptions = {
      method: "POST",
      headers: {"Content-Type": "application/json"},
      body: JSON.stringify(flArray)
    };

    const response = await fetch("https://nodejs.imaginecreativeagency.nl/dividenduitkering-live/dynamicGet/index.php", requestOptions);
    const data = await response.json();
    this.test = data.data
  },

}
</script>

<style scoped>

</style>
