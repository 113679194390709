// useTest.js
import { useRoute } from 'vue-router';
import { checkPermissionByEmail } from './check-permission-by-email';

export function useTest() {
    const route = useRoute();
    const { isUserExclusive } = checkPermissionByEmail();
    const createTest = async () => {
        const payStatus = route.params.paymentStatus;
        if ((payStatus && payStatus === 'succes') || isUserExclusive.value) {
            const storageUser = localStorage.getItem('user');
            if (storageUser) {
                const userId = JSON.parse(storageUser).data.userId;
                const date = new Date();
                const now = date.getTime();
                const randomPart = Math.random().toString(36).substring(2, 30);
                const uniqueId = `${now}_${randomPart}`;
                const obj = {
                    userId: userId,
                    date: formatDate(date),
                    testUnique: uniqueId,
                    testJson: {
                        questionProgress: JSON.stringify([]),
                        calculationData: JSON.stringify([]),
                    },
                };
                const requestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(obj),
                };
                const response = await fetch('https://nodejs.imaginecreativeagency.nl/dividenduitkering-live/tests/index.php', requestOptions);
                return await response.json();
            } else {
                return { success: false, data: 'Er is iets misgegaan.' };
            }
        }
    };

    const formatDate = (date) => {
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        return `${day}-${month}-${year} ${hours}:${minutes}`;
    };

    return {
        createTest,
    };
}
