<template>
  <!--  Als er data is het vraagnummer in titel weergeven. Bij toelichting antwoordtype 6 is dit anders.-->
  <title v-if="isData == false">Vragenlijst | Dividenduitkering.nl</title>
  <title v-if="isData == true && questionResult.answerType != '6'">Vragenlijst | Vraag {{ questionResult.questionNumber
    }}{{ questionResult.questionSubNumber }}</title>
  <title v-if="isData == true && questionResult.answerType == '6'">Vragenlijst | Toelichting </title>
  <div class="container-fluid cpxm">
    <div class="d-flex justify-content-between position-relative">
      <div class=" col-md-7 col-lg-9 vl-parent" v-if="loadingError == false" :class="{
        'min-height height  overflow-auto cpyl questionDetail': !windowCheckerData.isMobileScreen,
        'cpm': windowCheckerData.isMobileScreen,
        'min-height-mobile-s  height-mobile overflow-auto': windowCheckerData.isMobileScreenS,
        'min-height-mobile-xs  height-mobile-xs  overflow-auto': windowCheckerData.isMobileScreenXS}">

        <!--          Als een vraag wordt geladen de loader weergeven-->
        <loading v-model:active="questionLoading" :loader="'spinner'" :lock-scroll="false" :color="'#032B28'"
                 :backgroundColor="'#fff'" :can-cancel="true" :width="38" :transition="'fade'" :is-full-page="false"/>
        <div class="h-89" v-if="isData == true">
          <div v-if="questionResult.answerType != '6'" class=""
               :class="{'cmbs': windowCheckerData.isMobileScreen }">
            <div class="questionNumber position-relative">
              <h1> {{ questionResult.questionNumber }}{{ questionResult.questionSubNumber }}</h1>
              <div class="button-container" v-if="questionResult?.questionInfo">
                <img @click="showInfoModal()"
                     class="du-question-info d-inline-block" src="../assets/DRV_icon_info_blue.svg"
                     alt="">
              </div>
            </div>
          </div>

          <div
              v-if="questionResult.answerType == '3' || questionResult.answerType == '5' || questionResult.answerType == '6' || questionResult.answerType == '8' || questionResult.answerType == '9'"
              class="du-content" :class="{
                'du-content-mobile': windowCheckerData.isMobileScreen,
                'du-content-desktop': !windowCheckerData.isMobileScreen,
              }">
            <!--                Als de vraag NIET 84A is-->
            <div
                v-if="questionResult.questionNumber+questionResult.questionSubNumber != '84A' && questionResult.answerType != '6'"
                class="du-question-title">
              <h2 :class="{
                    'w-auto mw-100': windowCheckerData.isMobileScreen, 'd-inline-block': true
                  }"><img @click="showInfoModal()" v-if="questionResult?.questionInfo"
                          class="du-question-info d-inline-block" src="../assets/DRV_icon_info_blue.svg"
                          alt=""><span v-html="questionResult.questionName"></span></h2>
            </div>

            <!--                Alleen voor 84A heb ik een custom tekst om weer te geven-->
            <div v-if="questionResult.questionNumber+questionResult.questionSubNumber == '84A'"
                 class="du-question-title">
              <h2 class="d-inline-block">
                <span>Vul bij de volgende vragen het saldi in welke blijken uit de aktuele cijfers:</span>
              </h2>

<!--              <h2 :class="{-->
<!--                    'w-auto mw-100': windowCheckerData.isMobileScreen-->
<!--                  }"></h2>-->
              <p style="margin-top: 2vh;" v-if="questionResult.questionNumber == '84'">
                <span v-html="questionResult.questionName"></span></p>
            </div>

            <!--                Kop voor toelichting bij vraagtype 6-->
            <div v-if="questionResult.answerType == '6'" class="col-12 container-content"
                 :class="{'cptl cpbm': !windowCheckerData.isMobileScreen, 'cptm cpxm' : windowCheckerData.isMobileScreen, 'font-dark': !isDarkPage}">
              <div class="cmbm position-relative">
                <h2 class="largeTextXl" v-html="questionResult.questionTitle"></h2>
                <div class="button-container">
                  <img @click="showInfoModal()" v-if="questionResult?.questionInfo"
                       class="du-question-info d-inline-block" src="../assets/DRV_icon_info_blue.svg"
                       alt="">
                </div>
              </div>
              <div class="du-controls-content d-flex">
                <div class="w-100">
                  <div class="text-start" v-html="questionResult.questionDesc"></div>
                  </div>
              </div>
            </div>

            <!--                als het antwoordtype open of een nummer is dan p tag laten zien-->
            <div v-if="questionResult.answerType != ('6' && '8' && '9') && questionResult.useInputFields == '1'"
                 class="du-question-cta">
              <p>Vul uw antwoord(en) in:</p>
            </div>

            <!--                als het antwoordtype referentie is dan p tag laten zien-->
            <div class="du-question-description">
              <p v-if="questionResult.answerType == '9'"> {{ questionResult.answerQuestion[0].questionLbl }}</p>
            </div>

            <!--                bij vraagtype open, verschillende opties waaronder datepickers-->
            <div v-if="questionResult.answerType == '3'" class="du-question-input mt-2">
              <input
                  v-if="questionResult.questionNumber+questionResult.questionSubNumber != '12' && questionResult.questionNumber+questionResult.questionSubNumber != '13' && questionResult.questionNumber+questionResult.questionSubNumber != '82' && questionResult.questionNumber+questionResult.questionSubNumber != '112'"
                  type="text" v-model="selectedAnswer.answerText" class="du-input">
              <input
                  v-if="questionResult.questionNumber+questionResult.questionSubNumber == '12' || questionResult.questionNumber+questionResult.questionSubNumber == '82'"
                  type="date" v-model="unformattedDate" :max="maxDate" @change="setFormattedDate" class="du-input pe-3">
              <input v-if="questionResult.questionNumber+questionResult.questionSubNumber == '13'" type="date"
                     v-model="unformattedDate" @change="setFormattedDate" :min="minDateQ13" class="du-input pe-3">
              <input v-if="questionResult.questionNumber+questionResult.questionSubNumber == '112'" type="date"
                     v-model="unformattedDate" @change="setFormattedDate" max="" class="du-input pe-3">
            </div>


            <div v-if="questionResult.answerType == '9' && questionResult.answerQuestion[0].noNumber == '0'"
                 class="du-question-input-number mt-2">
              <!--                  ALS ER EEN NUMMER MOET WORDEN WEERGEGEVEN DAN DEZE LATEN ZIEN-->
              <div v-if="controlCalculation.status == true && selectedAnswer.answerText != (null || undefined)"
                   class="du-input-wrapper">
                <input type="text" v-model="selectedAnswer.answerText"
                       v-bind:class="controlCalculation.next == false ?'du-input du-input-disabled du-input-number-money du-input-negative':'du-input du-input-disabled du-input-number-money du-input-positive'"
                       disabled>
                <i class="fa fa-euro-sign input-icon"></i>
              </div>
              <!--                  als er een controlberekening is maar het atnwoord goed is-->
              <div v-if="controlCalculation.status == false  && selectedAnswer.answerText != (null || undefined)"
                   class="du-input-wrapper">
                <input type="text" v-model="selectedAnswer.answerText"
                       class="du-input du-input-disabled du-input-number-money" disabled>
                <i class="fa fa-euro-sign input-icon"></i>
              </div>
              <!--                  als er geen controleberekening is en selectedAnswer.answerText is null of op null gezet.-->
              <div v-if="controlCalculation.status == false && selectedAnswer.answerText == (null || undefined)"
                   class="du-question-input mt-2">
                <input type="text" v-model="placeholderText" class="du-input du-input-disabled" disabled>
              </div>
            </div>

            <!--                als het antwoordtype 9 is en het geen nummer is-->
            <div v-if="questionResult.answerType == '9' && questionResult.answerQuestion[0].noNumber == '1'"
                 class="du-question-input mt-2">
              <!--                  ALS ER GEEN NUMMER MOET WORDEN WEERGEGEVEN DAN DEZE LATEN ZIEN-->
              <input v-if="questionResult.useInputFields == '0'" type="text" v-model="selectedAnswer.answerText"
                     class="du-input du-input-disabled" disabled>
              <div v-if="questionResult.useInputFields == '1'" v-for="(item,index) in inputFields" class="mb-2">
                <div class="du-input-wrapper mb-4">
                  <div class="du-inputfield-wrapper mt-1">
                    <input type="text" :value="item" class="du-input du-inputfield du-input-disabled" disabled>
                  </div>
                </div>
              </div>

              <!--                  Als inputfields aanstaat maar er geen velden zijn-->
              <input v-if="!inputFields.length && questionResult.useInputFields == '1'" type="text"
                     value="Geen gegevens gevonden." class="du-input du-inputfield du-input-disabled" disabled>

            </div>

            <!--                als het antwoorddtype nummer is dan deze weergeven-->
            <div v-if="questionResult.answerType == '5'" class="du-question-input-number mt-2">
              <div class="du-input-wrapper custom-du-input-wrapper" v-if="questionResult.questionNumber != '204'">
                <!--                    <input type="number" oninput="this.questionResult.answerQuestion[0].positiveValue == '1' ? this.value=this.value.replace(/[^0-9\-,.]/g, '') : this.value=this.value.replace(/[^0-9,\.]/g, '')" v-model="selectedAnswer.answerText" class="du-input du-input-number-money">-->
                <span class="position-relative" v-if="questionResult.questionNumber == '2'">
<!--                      <input type="text" @input="restrictInput(true, this.questionResult.answerQuestion[0].positiveValue, 0)" v-model="selectedAnswer.answerText" class="du-input du-input-calc-percent">-->
                      <input ref="percentInput" class="du-input du-input-calc-percent" type="text"
                             pattern="^[0-9.,]+$" v-model="selectedAnswer.answerText" @input="formatPercent"/>
                      <span class="d-none input-warning">Alleen cijfers zijn toegestaan.</span>
                      <i class="fa fa-percent input-icon"></i>
                    </span>
                <span v-if="questionResult.questionNumber != '2'">
                      <input type="text"
                             @input="restrictInput(true, this.questionResult.answerQuestion[0].positiveValue, 0)"
                             v-model="selectedAnswer.answerText" class="du-input du-input-number-money">
                      <i class="fa fa-euro-sign input-icon"></i>
                    </span>
              </div>
              <input v-if="questionResult.questionNumber == '204'" type="text"
                     @input="restrictInput(true, this.questionResult.answerQuestion[0].positiveValue, 0)"
                     v-model="selectedAnswer.answerText" class="du-input">
            </div>

            <!--                als het antwoordtype invoervelden is en géén 202 -->
            <div class="du-question-input mt-4">
              <div v-for="(item,index) in questionResult.answerQuestion"
                   v-if="questionResult.answerType == '8' && questionResult.questionNumber != '202'" class="mb-2">
                <div class="du-input-wrapper mb-4">
                  <p class="du-inputfields-label"> {{ item.questionLbl }} </p>
                  <div v-if="questionResult.answerQuestion[0].noNumber == '0' && questionResult.requireAllFields == '1'"
                       class="du-inputfield-wrapper mt-1">
                    <input type="text" v-model="inputFields[index]"
                           @keydown.tab="inputFieldCheck(index,'tab', item.answerRequired)"
                           @input="restrictInput( false, this.questionResult.answerQuestion[index].positiveValue, index); inputFieldCheck(index,'', item.answerRequired)"
                           class="du-input du-inputfield du-input-number-money">
                    <i class="fa fa-euro-sign input-icon"></i>
                  </div>
                  <div v-if="questionResult.answerQuestion[0].noNumber == '1' && questionResult.requireAllFields == '1'"
                       class="du-inputfield-wrapper mt-1">
                    <input type="text" v-model="inputFields[index]"
                           @input="inputFieldCheck(index,'', item.answerRequired)"
                           class="du-input du-inputfield">
                  </div>
                  <div v-if="questionResult.answerQuestion[0].noNumber == '1' && questionResult.requireAllFields == '0'"
                       class="du-inputfield-wrapper mt-1">
                    <input type="text" v-model="inputFields[index]"
                           @input="inputFieldCheck(index,'', item.answerRequired)"
                           class="du-input du-inputfield">
                  </div>
                </div>
              </div>

              <!--                  Als het gaat om bestuurleden-->
              <div v-for="(item,index) in boardMembers" v-if="questionResult.questionNumber == '202'" class="mb-2">
                <div class="du-input-wrapper mb-4">
                  <p class="du-inputfields-label"> {{ item.questionLbl }} </p>
                  <div v-if="questionResult.answerQuestion[0].noNumber == '1' && questionResult.requireAllFields == '1'"
                       class="du-inputfield-wrapper mt-1">
                    <input type="text" v-model="inputFields[index]"
                           @input="inputFieldCheck(index,'', item.answerRequired)"
                           class="du-input du-inputfield">
                  </div>
                </div>
              </div>
            </div>

          </div>

          <div
              v-if="questionResult.answerType == '1' || questionResult.answerType == '2' || questionResult.answerType == '4' || questionResult.answerType == '7'"
              class="du-content" :class="{
                'du-content-mobile': windowCheckerData.isMobileScreen,
                'du-content-desktop': !windowCheckerData.isMobileScreen,
              }">
            <!--                Info modal weergeven bij een toelichting-->
            <div class="du-question-title">
              <h2 :class="{
                    'w-auto mw-100': windowCheckerData.isMobileScreen, 'd-inline-block': true
                  }"><img @click="showInfoModal()" v-if="questionResult?.questionInfo"
                          class="du-question-info d-inline-block" src="../assets/DRV_icon_info_blue.svg"
                          alt="">
                <span v-html="questionResult.questionName"></span></h2>
            </div>

            <div
                v-if="questionResult.answerType == '1' || questionResult.answerType == '2' || questionResult.answerType == '4'"
                class="du-radio-button-group mt-4"
                :class="{
                  'cpbs' : windowCheckerData.isMobileScreen
                }">
              <div class="du-question-cta">
                <p>Maak een keuze uit:</p>
              </div>
              <label v-for="(item, index) in questionResult.multipleChoice" class="du-radio du-radio-label du-label">
                <input v-if="questionResult.answerType != '2'" v-model="selectedAnswer" type="radio"
                       class="du-radio-btn" name="r" :value="item">
                <input v-if="questionResult.answerType == '2'" v-model="selectedAnswer"
                       @change="this.prefilledAnswer(this.questionResult.useFormula, this.questionResult.questionNumber, this.questionResult.questionSubNumber)"
                       type="radio" class="du-radio-btn" name="r" :value="item">
                <span> {{ item.answerText }}</span>
                <div
                    v-if="questionResult.answerType == '2' && item.addCommentary == '1' && selectedAnswer.answerID == item.answerID"
                    class="du-question-input mt-2">
                  <input type="text" v-model="selectedAnswer.commentary" placeholder="Geef een toelichting"
                         class="du-input du-comment d-block mb-4">
                </div>
              </label>

              <!--                  <input class="du-input" type="number" id="quantity" name="quantity" min="1" max="5"/>-->


            </div>


            <!-- MEERKEUZE meerdere antwoorden -->
            <div v-if="questionResult.answerType == '7'" class="du-checkbox-group mt-4">
              <div class="du-question-cta">
                <p>Kies één of meerdere antwoorden:</p>
              </div>
              <label v-for="(item, index) in questionResult.multipleChoice" :key="index"
                     class="du-radio du-radio-label du-checkbox du-label">
                <input type="checkbox" v-model="selectedAnswers" :true-value="[]" class="du-radio-btn" name="r"
                       :value="item">
                <span>{{ item.answerText }}</span>
              </label>
            </div>
          </div>
        </div>

        <div class="col-md-5 mx-0 question-buttons-container" v-if="loadingError == false" :class="{
          'cpym cpem': !windowCheckerData.isMobileScreen,
          'col-large': !windowCheckerData.isMobileScreen && !windowCheckerData.isTabletScreen,
          'col-medium': windowCheckerData.isTabletScreen,
          'cpxm cpys du-btn-container-mobile': windowCheckerData.isMobileScreen,
        }">

          <!--          antwoordtype waarbij bij input pas verder gegaan kan worden-->
          <div v-if="questionLoading == false && currentQuestionType == 0" class="d-flex justify-content-between">
            <!--            <div class="w-100">-->
            <div class="du-question-control-left d-inline-block">
              <router-link to="/start-aanvraag">
                <button v-if="firstLoad == true" class="du-button du-button-back">
                  <i class="fa-solid fa-chevron-left thick-icon"></i>VORIGE
                </button>
              </router-link>
              <button v-if="firstLoad == false && activePartTwo == false" @click="setModalData(questionResult,true)"
                      class="du-button du-button-back">
                <i class="fa-solid fa-chevron-left thick-icon"></i>GA TERUG
              </button>
              <button v-if="!firstLoad && questionResult.questionNumber != '201' && activePartTwo == true"
                      @click="setModalData(questionResult,true)" class="du-button du-button-back">
                <i class="fa-solid fa-chevron-left thick-icon"></i>GA TERUG
              </button>
            </div>

            <div class="du-question-control-right d-inline-block">
              <!--              <button class="du-button" @click="selectedAnswer = {}">Leeg selectedAnswer</button>-->
              <!--          ALS MIJN SELECTEDANSWER LEEG IS-->
              <!--              VOLGENDE LEEG SEL-ANSWER-->
              <button v-if="!Object.entries(selectedAnswer).length && !selectedAnswers.length"
                      class="du-button disabled-button">VOLGENDE
                <i class="fa-solid fa-chevron-right thick-icon"></i>
              </button>

              <!--          ALS MIJN SELECTEDANSWER GEVULD IS, FORMULE UIT STAAT EN ADDCOMMENTARY UIT STAAT OF NIET BESTAAT-->
              <!--              VOLGENDE VOL SEL-ANSWER ADDC 0 FORM 0-->
              <button
                  v-if="(Object.entries(selectedAnswer).length || selectedAnswers.length) && questionResult.useFormula == '0' && (selectedAnswer.addCommentary == '0' || !selectedAnswer.addCommentary)"
                  @click="nextID.linkType == '0' ? processQuestion(false, false,false, nextID.nextID, false, selectedAnswer, questionResult, false) : redirectToResult(nextID.nextID)"
                  class="du-button">VOLGENDE
                <i class="fa-solid fa-chevron-right thick-icon"></i>
              </button>

              <!--          ALS ADDCOMMENTARY AAN STAAT EN MIJN COMMENTAAR NOG LEEG / UNDEFINED IS DAN DE BUTTON DISABLED-->
              <!--              VOLGENDE VOL SEL-ANSWER ADDC 1 EMPTY COM-->
              <button
                  v-if="Object.entries(selectedAnswer).length && questionResult.useFormula == '0' && selectedAnswer.addCommentary == '1' && selectedAnswer.commentary == ''"
                  class="du-button disabled-button">VOLGENDE
                <i class="fa-solid fa-chevron-right thick-icon"></i>
              </button>

              <!--              VOLGENDE VOL SEL-ANSWER ADDC 1 FILLED COM-->
              <button
                  v-if="Object.entries(selectedAnswer).length && questionResult.useFormula == '0' && selectedAnswer.addCommentary == '1' && selectedAnswer.commentary != ''"
                  @click="nextID.linkType == '0' ? processQuestion(false, false,false, nextID.nextID, false, selectedAnswer, questionResult, false) : redirectToResult(nextID.nextID)"
                  class="du-button questionNextBtn">VOLGENDE
                <i class="fa-solid fa-chevron-right thick-icon"></i>
              </button>

              <!--          Als useFormula true is dan wordt customnumber meegegeven, nextQuestionNumbType9 en nextQuestionSubType9-->
              <!--              VOLGENDE (cSA = 0) addC 0-->
              <button
                  v-if="Object.entries(selectedAnswer).length && questionResult.useFormula == '1' && selectedAnswer.addCommentary == '0' && questionResult.checkSelectedAnswer == '0'&& questionResult.questionNumber != 71"
                  @click="processQuestion(false, false, useCustomNumber, nextQuestionNumbType9, nextQuestionSubType9,selectedAnswer, questionResult, false, true)"
                  class="du-button">VOLGENDE
                <i class="fa-solid fa-chevron-right thick-icon"></i>
              </button>

              <!--              VOLGENDE VOL SEL-ANSWER ADDC 1 EMPTY COM checkSelectedAnswer = 0-->
              <button
                  v-if="(Object.entries(selectedAnswer).length || selectedAnswers.length) && questionResult.useFormula == '1' && (selectedAnswer.addCommentary == '1' || !selectedAnswer.addCommentary) && selectedAnswer.commentary == '' && questionResult.checkSelectedAnswer == '0'"
                  @click="processQuestion(false, false, useCustomNumber, nextQuestionNumbType9, nextQuestionSubType9,selectedAnswer, questionResult, false, true)"
                  class="du-button disabled-button">VOLGENDE
                <i class="fa-solid fa-chevron-right thick-icon"></i>
              </button>

              <!--              VOLGENDE VOL SEL-ANSWER ADDC 1 FILLED COM checkSelectedAnswer = 0-->
              <button
                  v-if="(Object.entries(selectedAnswer).length || selectedAnswers.length) && questionResult.useFormula == '1' && (selectedAnswer.addCommentary == '1' || !selectedAnswer.addCommentary) && selectedAnswer.commentary != '' && questionResult.checkSelectedAnswer == '0'"
                  @click="processQuestion(false, false, useCustomNumber, nextQuestionNumbType9, nextQuestionSubType9,selectedAnswer, questionResult, false, true)"
                  class="du-button">VOLGENDE
                <i class="fa-solid fa-chevron-right thick-icon"></i>
              </button>

              <!--              VOLGENDE VOL SEL-ANSWER ADDC 1 EMPTY COM checkSelectedAnswer = 1-->
              <button
                  v-if="(Object.entries(selectedAnswer).length || selectedAnswers.length) && questionResult.useFormula == '1' && (selectedAnswer.addCommentary == '1' || !selectedAnswer.addCommentary) && selectedAnswer.commentary == '' && questionResult.checkSelectedAnswer == '1'"
                  @click="processQuestion(false, false, useCustomNumber, nextQuestionNumbType9, nextQuestionSubType9,selectedAnswer, questionResult, false, true)"
                  class="du-button disabled-button">VOLGENDE
                <i class="fa-solid fa-chevron-right thick-icon"></i>
              </button>

              <!--              VOLGENDE VOL SEL-ANSWER ADDC 1 FILLED COM checkSelectedAnswer = 1-->
              <button
                  v-if="((Object.entries(selectedAnswer).length || selectedAnswers.length) && questionResult.useFormula == '1' && (selectedAnswer.addCommentary == '1' || !selectedAnswer.addCommentary) && selectedAnswer.commentary != '' && questionResult.checkSelectedAnswer == '1') ||(Object.entries(selectedAnswer).length && questionResult.useFormula == '1' && selectedAnswer.addCommentary == '0' && questionResult.checkSelectedAnswer == '1')"
                  @click="processQuestion(false, true, true, nextID.nextID, false, selectedAnswer, questionResult, false)"
                  class="du-button">VOLGENDE
                <i class="fa-solid fa-chevron-right thick-icon"></i>
              </button>

            </div>
            <!--            </div>-->
          </div>

          <!--          als er geen input nodig is alvorens verder te gaan-->
          <!-- Knoppen onderaan -->
          <div v-if="questionLoading == false && currentQuestionType == 1" class="d-flex justify-content-between">
            <!--            <div class="w-100">-->
            <div class="du-question-control-left d-inline-block">

              <router-link to="/start-aanvraag">
                <button v-if="firstLoad == true" class="du-button du-button-back">
                  <i class="fa-solid fa-chevron-left thick-icon"></i>VORIGE
                </button>
              </router-link>

              <button v-if="firstLoad == false && activePartTwo == false" @click="setModalData(questionResult,true)"
                      class="du-button du-button-back">
                <i class="fa-solid fa-chevron-left thick-icon"></i>GA TERUG
              </button>

              <button v-if="!firstLoad && questionResult.questionNumber != '201' && activePartTwo == true"
                      @click="setModalData(questionResult,true)" class="du-button du-button-back">
                <i class="fa-solid fa-chevron-left thick-icon"></i>GA TERUG
              </button>

            </div>
            <div class="du-question-control-right d-inline-block">

              <!--              Als het invoerveld open of nummer leeg is-->
              <button
                  v-if="questionResult.answerType != '6' && questionResult.answerType != '8' && questionResult.answerType != '9' && (selectedAnswer.answerText == null || selectedAnswer.answerText == '' || selectedAnswer.answerText.length == 0)  && questionResult.questionNumber != '204'"
                  class="du-button disabled-button">VOLGENDE
                <i class="fa-solid fa-chevron-right thick-icon"></i>
              </button>

              <!--              Als het invoerveld open of nummer Niet leeg is-->
              <button
                  v-if="questionResult.answerType != '6' && questionResult.answerType != '8' && questionResult.answerType != '9' && selectedAnswer.answerText != null && selectedAnswer.answerText != '' && selectedAnswer.answerText.length != 0 && questionResult.questionNumber != '204'"
                  @click="processQuestion(false, false, false, nextID.nextID, false, selectedAnswer, questionResult, false)"
                  class="du-button"
                  v-bind:class="{'disabled-button': !isValid}"
                  :disabled="!isValid">VOLGENDE
                <i class="fa-solid fa-chevron-right thick-icon"></i>
              </button>

              <!--              Als 204 leeg is-->
              <button
                  v-if="(selectedAnswer.answerText == null || selectedAnswer.answerText == '' || selectedAnswer.answerText.length == 0)  && questionResult.questionNumber == '204'"
                  class="du-button disabled-button">AFRONDEN
                <i class="fa-solid fa-chevron-right thick-icon"></i>
              </button>

              <!--              Als 204 niet leeg is-->
              <button
                  v-if="questionResult.questionNumber == '204' && selectedAnswer.answerText != null && selectedAnswer.answerText != '' && selectedAnswer.answerText.length != 0"
                  @click="redirectToResult(nextID.nextID)" class="du-button">AFRONDEN
                <i class="fa-solid fa-chevron-right thick-icon"></i>
              </button>

              <!--              Als een toelichting geen achterliggende condities of bepalingen heeft-->
              <button v-if="questionResult.answerType == '6' && this.questionResult.useFormula == '0'"
                      @click="nextID.linkType == '0' ? processQuestion(false, false,false, nextID.nextID, false, selectedAnswer, questionResult, false) : redirectToResult(nextID.nextID)"
                      class="du-button">VOLGENDE
                <i class="fa-solid fa-chevron-right thick-icon"></i>
              </button>

              <!--              Als een toelichting wel achterliggende condities of bepalingen heeft-->
              <button v-if="questionResult.answerType == '6' && this.questionResult.useFormula == '1'"
                      @click="processQuestion(false, false, true, nextQuestionNumbType9, nextQuestionSubType9,selectedAnswer, questionResult, false, true)"
                      class="du-button">VOLGENDE
                <i class="fa-solid fa-chevron-right thick-icon"></i>
              </button>

              <!--              Referentie en resultRedirect uit staat-->
              <button v-if="questionResult.answerType == '9' && resultRedirect.status == false"
                      @click="this.questionResult.useFormula == '1' ? processQuestion(false, false, true, nextQuestionNumbType9, nextQuestionSubType9, selectedAnswer, questionResult, false, true) : processQuestion(false, false, false, nextID.nextID,false, selectedAnswer, questionResult, false,false)"
                      v-bind:class="controlCalculation.next == true ? 'du-button' : 'du-button disabled-button'">
                VOLGENDE
                <i class="fa-solid fa-chevron-right thick-icon"></i>
              </button>

              <!--              Referentie en resultRedirect aan staat-->
              <button v-if="questionResult.answerType == '9' && resultRedirect.status == true"
                      @click="redirectToResult(resultRedirect.resultNr)" class="du-button">VOLGENDE
                <i class="fa-solid fa-chevron-right thick-icon"></i>
              </button>

              <!--              als alle invoervelden verplicht zijn en ze nog niet allemaal ingevuld zijn-->
              <button
                  v-if="questionResult.answerType == '8' && allInputFieldsFilled == false && questionResult.requireAllFields == '1'"
                  class="du-button disabled-button">VOLGENDE
                <i class="fa-solid fa-chevron-right thick-icon"></i>
              </button>

              <!--              als alle invoervelden verplicht zijn en ze wel allemaal ingevuld zijn-->
              <button
                  v-if="questionResult.answerType == '8' && allInputFieldsFilled == true  && questionResult.requireAllFields == '1'"
                  @click="this.questionResult.useFormula == '1' ? processQuestion(false, true, true, nextID.nextID, false, selectedAnswer, questionResult, false) : processQuestion(false, false, false, nextID.nextID,false, selectedAnswer, questionResult, false)"
                  class="du-button">VOLGENDE
                <i class="fa-solid fa-chevron-right thick-icon"></i>
              </button>

              <!--              als niet alle invoervelden verplicht zijn-->
              <button v-if="questionResult.answerType == '8' && questionResult.requireAllFields == '0'"
                      @click="this.questionResult.useFormula == '1' ? processQuestion(false, true, true, nextID.nextID, false, selectedAnswer, questionResult, false) : processQuestion(false, false, false, nextID.nextID,false, selectedAnswer, questionResult, false)"
                      class="du-button">VOLGENDE
                <i class="fa-solid fa-chevron-right thick-icon"></i>
              </button>
            </div>
          </div>
        </div>
      </div>

      <!--        als er een error optreedt-->
      <div class="col-12 du-column pb-0 flex-column-reverse justify-content-center" :class="{
        'min-height height scroller cpyl cpm': !windowCheckerData.isMobileScreen,
        'cpm': windowCheckerData.isMobileScreen,
        'col-lg-9': loadingError == false,
        'min-height-mobile-s  height-mobile scroller': windowCheckerData.isMobileScreenS,
        'min-height-mobile-xs  height-mobile-xs scroller': windowCheckerData.isMobileScreenXS}"
           v-if="loadingError == true">
        <div class="row du-between-content">

          <div class="col-12">
            <div class="du-title-text du-between-title d-flex justify-content-center text-center">
              <h1>Er is iets misgegaan</h1>
            </div>
          </div>

          <div class="col-12">
            <div class="du-between-subtext d-flex justify-content-center">
              <p :class="{'w-100': windowCheckerData.isMobileScreen}">Er heeft een onverwachte fout opgetreden. Probeer
                het later nog een keer.</p>
            </div>
          </div>

          <!--            redirect naar home-->
          <div class="col-12">
            <div class="mt-3 d-flex justify-content-center">
              <router-link to="/start-aanvraag">
                <div class="du-question-control-right d-inline-block">
                  <button id="verification-button" class="du-button du-button-dark">NAAR HOMEPAGE
                    <i class="fa-solid fa-chevron-right thick-icon"></i>
                  </button>
                </div>
              </router-link>
            </div>
          </div>
          <div class="col-12">
            <div class="mt-3 d-flex justify-content-center">

              <div class="du-question-control-right d-inline-block" @click="resetTest()">
                <button id="verification-button" class="du-button du-button-dark">OPNIEUW DE TOETS
                  <i class="fa-solid fa-chevron-right thick-icon"></i>
                </button>
              </div>
            </div>
          </div>

        </div>
      </div>

      <!--    progressbar opbouw-->
      <div v-if="loadingError == false" class="  col-lg-3 du-progressbar scroller"
           ref="scrollableProgress" id="progressBoxes" :class="{
        'cpyl cpm min-height height': !windowCheckerData.isMobileScreen,
        'cpm du-progressbar-hidden': windowCheckerData.isMobileScreen}">
        <div class="du-question-progression">
          <div
              class="du-progress-boxes d-flex justify-content-center flex-column d-flex justify-content-center d-md-block d-none ">
            <div v-for="(item, index) in questionProgress">

              <div
                  v-if="questionProgress.length && !item.params?.questionResult?.questionHidden"
                  @click="activePartTwo == true && Number(item.questionNumber.replace(/[^0-9]/g, '')) < 201 ? null : setModalData(item, false, index)">
                <div v-if="item.answerType != '6'"
                     :class="activePartTwo == true && Number(item.questionNumber.replace(/[^0-9]/g, '')) < 201 ? 'du-progress-box du-progress-box-disabled mx-auto mw-100 w-100':'du-progress-box mx-auto mw-100 w-100'"
                >

                  <div class="du-progressbox-number-box d-flex justify-content-center">

                    <p class="du-progressbox-number d-flex align-items-center justify-content-center">
                        <span>

                          {{ item.questionNumber }}</span>
                      <hr class="hr-inline">
                      <i
                          v-if="activePartTwo == true && Number(item.questionNumber.replace(/[^0-9]/g, '')) < 201"
                          class="fa fa-check du-progress-number-check" aria-hidden="true"></i>
                    </p>


                  </div>
                  <p class="du-progressbox-title" :class="{
                    'w-100 cpxs ':! windowCheckerData.isMobileScreen
                  }" v-html="item.questionName"></p>
                  <p class="du-progressbox-answer" :class="{
                    'w-100 cpxs':! windowCheckerData.isMobileScreen
                  }" v-html="item.questionAnswer"></p>
                  <!-- <div v-if="questionProgress.length >= 1"
                       class="du-progressbox-connect w-100 d-flex justify-content-center">
                    <div class="du-progressbox-connector">
                    </div>
                  </div> -->
                </div>
              </div>
            </div>
          </div>

          <!--            modal bij het klikken op 1 van de eerdere ingevulde vragen in de progressbar rechts-->
          <div class="modal fade drv-modal" id="RetQuestionsModal" ref="Modal" tabindex="-1" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
              <div class="modal-content">

                <div class="modal-header">
                  <h2 class="modal-title fs-5 du-text-bold du-text-darkblue" id="drv-deletequestion-lbl">
                    <span>LET OP!</span></h2>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>

                <div class="modal-body du-text-darkblue"><p class="text-start">Als u terug gaat naar
                  <span>vraag {{ modalData.questionNumber }}</span>,
                  zult u <span>{{ modalData.deleteCount }}</span> <span style="font-weight: 500"
                                                                        v-if="modalData.deleteCount > 1">van de (hierna) ingevulde antwoorden</span>
                  <span style="font-weight: 500" v-if="modalData.deleteCount == 1">(hierna) ingevuld antwoord </span>kwijt
                  raken.</p></div>

                <div class="modal-footer">
                  <button type="button" class="du-button du-button-transparent du-modal-button" data-bs-dismiss="modal">
                    ANNULEER
                  </button>
                  <button type="button" class="du-button du-button-transparent du-modal-button du-modal-button-confirm"
                          data-bs-dismiss="modal" @click="returnToQuestion(true, modalData.index)">NAAR VRAAG
                  </button>
                </div>

              </div>
            </div>
          </div>

          <!--            modal bij het klikken op vorige-->
          <div class="modal fade drv-modal" id="RetSingQuestionModal" ref="Modal" tabindex="-1" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
              <div class="modal-content">

                <div class="modal-header">
                  <h2 class="modal-title fs-5 du-text-bold du-text-darkblue" id="drv-deletequestion-lbl">
                    <span>LET OP!</span></h2>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>

                <div class="modal-body du-text-darkblue"><p class="text-start">Weet u zeker dat u naar de vorige vraag
                  wilt? Bij het klikken op <span>naar vraag</span>, zult u het antwoord van de huidige vraag
                  ({{ modalData.questionNumber + modalData.subID }}) kwijtraken.</p></div>

                <div class="modal-footer">
                  <button type="button" class="du-button du-button-transparent du-modal-button" data-bs-dismiss="modal">
                    ANNULEER
                  </button>
                  <button type="button" class="du-button du-button-transparent du-modal-button du-modal-button-confirm"
                          data-bs-dismiss="modal" @click="returnToQuestion(false)">NAAR VRAAG
                  </button>
                </div>

              </div>
            </div>
          </div>

          <!--            Modal om naar deel 2 te gaan-->
          <div class="modal fade drv-modal" id="part2Modal" ref="Modal" tabindex="-1" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
              <div class="modal-content">

                <div class="modal-header">
                  <h2 class="modal-title fs-5 du-text-bold du-text-darkblue" id="drv-deletequestion-lbl">
                    <span>LET OP!</span></h2>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>

                <div class="modal-body du-text-darkblue"><p class="text-start"><strong>U gaat nu door naar het laatste
                  deel van de toets.</strong><br> <br> Indien u klikt op <span>volgende</span> is er <span>geen</span>
                  mogelijkheid meer om terug te gaan naar dit deel van de vragenlijst. Controleer dus goed of alles
                  correct is ingevuld. <br><br> De proef PDF kan u hierbij helpen. Indien u op die button drukt, gaat er
                  geen data verloren</p></div>

                <div class="modal-footer">
                  <!--                  DIMMMY -->
                  <div style="display: block;width: 100%;text-align: right;">
                    <button style="width: 217px;" type="button" class="du-button du-button-transparent du-modal-button"
                            data-bs-dismiss="modal" @click="createPDFToServer()">
                      Bekijk uw proef PDF
                    </button>
                  </div>
                  <!--                  END DIMMMY-->
                  <button type="button" class="du-button du-button-transparent du-modal-button" data-bs-dismiss="modal">
                    ANNULEER
                  </button>
                  <button type="button" class="du-button du-button-transparent du-modal-button du-modal-button-confirm"
                          data-bs-dismiss="modal" @click="closePartOne()">VOLGENDE
                  </button>
                </div>


              </div>
            </div>
          </div>

          <!--            Modal om het tweede deel van de toets af te sluiten-->
          <div class="modal fade drv-modal" id="endPart2Modal" ref="endPart2Modal" tabindex="-1" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
              <div class="modal-content">

                <div class="modal-header">
                  <h2 class="modal-title fs-5 du-text-bold du-text-darkblue" id="drv-deletequestion-lbl">
                    <span>LET OP!</span></h2>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>

                <div class="modal-body du-text-darkblue"><p class="text-start"><strong>U sluit nu het tweede deel van de
                  toets.</strong><br> <br> Indien u klikt op <span>volgende</span> is er <span>geen</span> mogelijkheid
                  meer om terug te gaan naar dit deel van de vragenlijst. Controleer dus goed of alles correct is
                  ingevuld. Uw aanvraag wordt naar uw opgegeven e-mailadres verstuurd. Dit kan een paar minuten duren.
                  Het is mogelijk dat uw aanvraag in uw spam box wordt verstuurd.</p></div>

                <div class="modal-footer">
                  <button type="button" class="du-button du-button-transparent du-modal-button" data-bs-dismiss="modal">
                    ANNULEER
                  </button>
                  <button type="button" class="du-button du-button-transparent du-modal-button du-modal-button-confirm"
                          data-bs-dismiss="modal" @click="completeTest()">AFRONDEN
                  </button>
                </div>

              </div>
            </div>
          </div>

          <!--            informatie bij een vraag-->
          <div v-if="questionResult?.questionInfo" class="modal fade" id="questionInfoModal" ref="questionInfoModal"
               tabindex="-1" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered question-info-modal">
              <div class="modal-content">

                <div class="modal-header">
                  <h2 class="modal-title fs-5 du-text-bold du-text-darkblue" id="drv-deletequestion-lbl"><span>Aanvullende informatie</span>
                  </h2>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>

                <div class="modal-body du-text-darkblue">
                  <p v-html="questionResult?.questionInfo" class="text-start"></p>
                </div>

              </div>
            </div>
          </div>

          <!--            vanzelfsprekend-->
          <div class="du-questionprogress-current d-flex justify-content-center">
            <div class="du-questionprogress-currentblock d-flex align-items-center">
              <p>Huidige vraag</p>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
  <WindowChecker @resize-window="windowChecker"></WindowChecker>
</template>

<script>
import Navigation from "@/components/Navigation.vue";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import Loading from 'vue-loading-overlay';
import {round} from 'lodash';
import 'vue-loading-overlay/dist/css/index.css';
import modal from "bootstrap/js/src/modal";
import {useUserStore} from '@/store/user'
import {storeToRefs} from 'pinia'
import {next} from "lodash/seq";
import moment from "moment";
import {onBeforeRouteLeave, onBeforeRouteUpdate} from 'vue-router'
import {ref} from 'vue';
import WindowChecker from "@/components/WindowChecker.vue";
import {useAuth0} from "@auth0/auth0-vue";
import dummyCalc from "./dummy-calculation.json";
import dummyProg from "./dummy-progression.json";
import dummyShowProg from "./dummy-show-progression.json";
import {useTheme} from "@/components/utils/theme";
import {checkPermissionByEmail} from "@/components/utils/check-permission-by-email";

let userStore
let userVar
let loggedIn

export default {
  name: "QuestionView",
  computed: {
    modal() {
      return modal
    },

    // bestuursleden cijfer ophalen door alleen cijfers over te houden van antwoord
    boardMembers() {
      // Generate an array of the desired length
      let ans201 = this.questionProgress.find(obj => obj.questionNumber == '201') != undefined ? this.questionProgress.find(obj => obj.questionNumber == '201').questionAnswer : undefined
      let loopLength = Number(ans201.replace(/[^0-9]/g, ''))
      this.inputFieldCount = loopLength
      return Array.from({length: loopLength}, (_, index) => this.questionResult.answerQuestion[index]);
    },

    // datum van vandaag ophalen
    maxDate() {
      const today = new Date().toISOString().split('T')[0];
      return today;
    },

    // minimale datum (vraag 12) gebruiken voor vraag 13
    minDateQ13() {
      let dateString = this.questionProgress.find(obj => obj.questionNumber == '12').questionAnswer;
      let dateParts = dateString.split('-');
      let reversedDateString = dateParts[2] + '-' + dateParts[1] + '-' + dateParts[0];
      let dateObject = new Date(reversedDateString)
      const date = dateObject.toISOString().split('T')[0];
      return date;
    },
  },
  components: {WindowChecker, Navigation, Header, Footer, Loading, moment},

  // auth0 initialiseren
  setup() {
    const auth0 = useAuth0();
    const {isDarkPage} = useTheme()
    const { isUserExclusive } = checkPermissionByEmail();

    return {
      isDarkPage,
      isUserExclusive,
      isAuthenticated: auth0.isAuthenticated,
      isLoading: auth0.isLoading,
      user: auth0.user,
    }
  },

  data() {
    return {
      notAllowedToEnterTest: false,
      firstQuestion : false,
      currentTest: null,
      testID: 0,
      isValid: true,
      createdPDF: false,
      percentValue: '',
      price: null,
      previousprice: null,
      windowCheckerData: {
        isMobileScreenXS: false,
        isMobileScreenS: false,
        isMobileScreen: false,
        isTabletScreen: false
      },
      showWarning: true,
      isData: false,
      firstLoad: true,
      loadingError: false,
      questionLoading: true,
      customQNumber: true,
      currentQuestionID: null,
      currentQuestion: [],
      currentQuestionType: null,
      questionResult: null,
      resultRedirect: {
        status: false,
        resultNr: null
      },
      controlCalculation: {
        status: false,
        next: true
      },
      unformattedDate: null,
      percentageQuestion: null,
      selectedAnswer: {},
      placeholderText: "Helaas is er iets mis gegaan.",
      selectedAnswers: [],
      nextID: {},
      questionProgress: [],
      useCustomNumber: false,
      nextQuestionNumbType9: '',
      nextQuestionSubType9: false,
      futureResultNumber: null,
      redirectedQuestionNumb: null,
      redirectedQuestionSub: null,
      modalData: {},
      inputFieldCount: 0,
      inputFields: [],
      allInputFieldsFilled: false,
      dateFormat: 'dd-MM-yyyy',
      activePartTwo: false,
      calculationData: [],
    }
  },


  watch: {
    selectedAnswer: function (value) {
      if (this.selectedAnswer.addCommentary == '1') {
        this.selectedAnswer.commentary = ""
      }
      //ALS HET TYPE GEEN MEERKEUZE IS, DAN CHECKT HIJ OF ER SELECTEDANSWER IS (OBJECT.ENTRIES) EN KIJKT HIJ NAAR HET LINKTYPE
      //LINKTYPE 1 IS RESULTAAT EN 0 IS EEN VRAAG. BIJ 1 WORDT ER DUS EEN NEXTRESULT MEEGEGEVEN EN BIJ 0 IS DIT EEN NEXTQUESTIONID VOOR DE QUERY.
      if (this.questionResult.answerType != '7') {
        if (Object.entries(value).length && value.linkType == '1') {
          this.nextID.linkType = value.linkType
          this.nextID.nextID = value.nextResult
        } else if (Object.entries(value).length && value.linkType == '0') {
          this.nextID.linkType = value.linkType
          this.nextID.nextID = value.nextQuestionID
        }
      }
    },

    selectedAnswers: function (value) {
      //ALS DE LENGTE VAN DE SELECTEDANSWERS GROTER IS DAN 1 & LINKTYPE IS GELIJK AAN 1, DAN WEER HET LINKTYPE EN NEXTQUESTIONID TOEWIJZEN
      if (value.length >= 1 && this.questionLoading == false && value[0].linkType == '1') {
        this.nextID.linkType = value[0].linkType
        this.nextID.nextID = value[0].nextQuestionID
        //ALS DE LENGTE VAN DE SELECTEDANSWERS GROTER IS DAN 0 & LINKTYPE GELIJK IS AAN 0, DAN LINKTYPE EN NEXTQUESTIONID
      } else if (value.length >= 1 && this.questionLoading == false && value[0].linkType == '0') {
        this.nextID.linkType = value[0].linkType
        this.nextID.nextID = value[0].nextQuestionID
        //BIJ EEN LEGE ARRAY (GEEN ANTWOORDEN GEKOZEN) DE NEXTID WEER LEEG MAKEN.
      } else if (value.length == 0) {
        this.nextID = {}
      }
    },

    questionProgress: {
      handler(val, oldVal) {
        if (val.length == 0) {
          this.firstLoad = true
        }
      },
      deep: true
    },
  },

  // browser waarschuwing bij verlaten toets
  async beforeRouteLeave(to, from, next) {
    const storageUser = localStorage.getItem('user');
    if(!this.notAllowedToEnterTest) {
      await this.activateMonitor(true)
    }
    next()
  },

  methods: {

    formatPercent() {
      const validation = /^[0-9]+(?:\.[0-9]+)?(?:,\d{0,2})?$/
      this.selectedAnswer.answerText = this.selectedAnswer.answerText.replace(',', '.');
      this.isValid = validation.test(this.selectedAnswer.answerText)
    },

    showWindow() {
      // Verstuur de update naar de server
      const storageUser = localStorage.getItem('user');
      const id = this.$route.params.id;
      let status = 'lopend'
      // if(!this.notAllowedToEnterTest){

        if(this.firstQuestion){
          status = 'nieuw'
        }
        const obj = {
          remove_current_user: true,
          test_current_user_id: JSON.parse(storageUser).data.userId,
          test_status: status,
          test_unique_id: id,
        };
        if(!this.notAllowedToEnterTest) {
          navigator.sendBeacon("https://nodejs.imaginecreativeagency.nl/dividenduitkering-live/tests/index.php", JSON.stringify(obj));
        }
      // }
      // const confirmationMessage = 'Are you sure you want to leave?';
      // Standard for most browsers
      // event.returnValue = confirmationMessage;
      // For some older browsers
      // return confirmationMessage;
    },

    windowChecker(data) {
      this.windowCheckerData = data;
    },

    // aantal nullen formateren
    formatZeros(value, parse) {
      if (value != undefined) {
        let formattedValue = value
        if (parse == true) {
          const parsedFloat = parseFloat(formattedValue);
          formattedValue = parseFloat(parsedFloat.toFixed(2));
          formattedValue = String(formattedValue)
        } else {
          const parts = formattedValue.split('.');
          if (parts.length == 1) {
            return formattedValue + '.00';
          } else if (parts[1].length == 1) {
            return formattedValue + '0';
          } else {
            return formattedValue;
          }
        }
      } else {
        return null
      }
    },

    // datum formatteren bij input
    setFormattedDate() {
      this.selectedAnswer.answerText = moment(this.unformattedDate).format('DD-MM-YYYY')
    },

    // mogelijk maken van alleen cijfers of eventueel een min
    restrictInput(single, positive, index) {
      if (single == true) {
        let cleanedValue
        if (positive == '1') {
          // vervangen van karakters door empty spaces
          cleanedValue = this.selectedAnswer.answerText.replace(/[^0-9]/g, "");
        } else {
          // vervangen van karakters door empty spaces muv slashes
          cleanedValue = this.selectedAnswer.answerText.replace(/[^0-9-]/g, "");
        }
        this.selectedAnswer.answerText = cleanedValue

      } else {
        let cleanedValue
        if (positive == '1') {
          cleanedValue = this.inputFields[index].replace(/[^0-9]/g, "");
        } else {
          cleanedValue = this.inputFields[index].replace(/[^0-9-]/g, "");
        }
        this.inputFields[index] = cleanedValue
      }
    },

    // De progressbox aan de zijkant naar beneden laten scrollen na toevoegen
    scrollToBottom() {
      this.$nextTick(() => {
        const scrollableProgress = this.$refs.scrollableProgress;
        scrollableProgress.scrollTop = scrollableProgress.scrollHeight;
      });
    },


    // Het checken van de inputFields array voor vraagtype 8
    inputFieldCheck(index, key, isRequired) {
      let inputField = this.inputFields[index]
      //ALS ALLE VELDEN NIET VERPLICHT ZIJN, DAN VERWIJDER JE EEN OBJECT UIT DE ARRAY ALS DEZE LEEG IS.
      // OF ALS ZE WEL VERPLICHT ZIJN MAAR ER HOEFT GEEN NUMMER IN.
      if (this.questionResult.requireAllFields == '0') {
        if (inputField == '' || inputField == null || inputField == undefined) {
          this.inputFields[index] = null;
        }
      }

      //ALS ALLE VELDEN VERPLICHT ZIJN, DAN DE LEGE INPUTS AUTOMATISCH OP 0 ZETTEN.
      if (this.questionResult.requireAllFields == '1' && this.questionResult.answerQuestion[0].noNumber == '0') {

        if (key == 'tab') {
          if (inputField == '' || inputField == null || inputField == undefined) {
            this.inputFields[index] = '0'
          }
        }

        //ALS ALLES IS INGEVULD DAN VOER IK EEN LOOP UIT WAARBIJ HIJ KIJKT OF ER VELDEN ZIJN DIE NULL OF UNDEFINED ZIJN.
        //INDIEN DIT HET GEVAL IS. DEZE OP 0 ZETTEN.
        let succesCount = 0
        if (this.inputFields.length == this.inputFieldCount) {
          for (let i = 0; i < this.inputFields.length; i++) {
            if (this.inputFields[i] != null && this.inputFields[i] != undefined && this.inputFields[i] != '' || isRequired == '0') {
              succesCount++;
            } else {
              succesCount--;
            }
          }
          if (succesCount == this.inputFieldCount) {
            this.allInputFieldsFilled = true
          } else {
            this.allInputFieldsFilled = false
          }
        } else if (this.inputFields.length != this.inputFieldCount) {
          this.allInputFieldsFilled = false
        }
      } else if (this.questionResult.requireAllFields == '1' && this.questionResult.answerQuestion[0].noNumber == '1') {
        let succesCount = 0
        for (let i = 0; i < this.inputFields.length; i++) {
          if (this.inputFields[i] != null && this.inputFields[i] != undefined && this.inputFields[i] != '' || isRequired == '0') {
            succesCount++;
          } else {
            succesCount--;
          }
        }
        if (succesCount == this.inputFieldCount) {
          this.allInputFieldsFilled = true
        } else {
          this.allInputFieldsFilled = false
        }
      }
    },

    setModalData(item, singular, index) {
      // MODALDATA STANDAARD LEEG MAKEN WANNEER DE FUNCTIE WORDT OPGEROEPEN.
      // ALS ER 1 OBJECT/VRAAG MOET WORDEN VERWIJDERD, DAN ID, NUMBER AND NAME TOEWIJZEN.
      // BIJ MEERDERE VRAGEN WORDT DE INDEX VAN DE HUIDIGE VRAAG AFGETROKKEN VAN DE QUESTIONPROGRESS.
      this.modalData = {}
      if (singular == false) {
        this.modalData.ID = item.questionID
        this.modalData.questionNumber = item.questionNumber
        this.modalData.subID = item.questionSubNumber
        this.modalData.questionName = item.questionName
        this.modalData.answerValue = item.questionAnswer
        this.modalData.deleteCount = this.questionProgress.length - index
        this.modalData.index = index
        $('#RetQuestionsModal').modal('show');
      } else {
        this.modalData.ID = item.id
        this.modalData.questionNumber = item.questionNumber
        this.modalData.subID = item.questionSubNumber
        this.modalData.questionName = item.questionName
        $('#RetSingQuestionModal').modal('show');
      }
    },

    // modal weergeven
    showInfoModal() {
      $('#questionInfoModal').modal('show');
    },

    // een promise functie
    sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },

    //ALLE DATA RESETTEN
    resetData() {
      this.unformattedDate = null
      this.questionLoading = true
      this.resultRedirect.status = false
      this.controlCalculation.status = false
      this.controlCalculation.next = true
      this.currentQuestion = []
      this.questionResult = []
      this.selectedAnswer = {}
      this.selectedAnswers = []
      this.inputFields = []
      this.allInputFieldsFilled = false
      this.nextID = {}
      this.useCustomNumber = true
    },

    async resetTest() {
      localStorage.removeItem('yourLocalStorageKey_question')
      localStorage.removeItem('yourLocalStorageKey_calculation')
      this.questionResult = []
      this.questionProgress = []
      this.loadingError = false;
      await this.processQuestion(true, false, true, '1', ''
          , null, null, false)
    },


    // TERUGKEREN NAAR VRAAG
    async returnToQuestion(multiple, index) {
      this.loadingError = false
      if (this.questionProgress.length >= 1) {
        // ALS ER 1 VRAAG MOET WORDEN VERWIJDERD, DAN HAAL IK HET LAATSTE ID EN NUMMER OP. OOK MAAK IK EEN VARIABELE CURRENTQUESTION MET NUMMER + SUBNR.
        // EERST FILTER IK DE CALCULATIONDATA EN RETURN IK ALLES WAAR DE KEY NIET GELIJK IS AAN CURRENTQUESTION.
        // DAARNA FILTER IK ALLE CALCULATIONDATA WAARBIJ DE KEY NIET GELIJK IS AAN HET LAATSTE NUMMER. GEWOON EEN DUBBELE CHECK.
        // VERVOLGENS WIJS IK DEZE NIEUWE ARRAY TOE AAN DE VARIABELE CALCULATIONDATA
        if (multiple == false) {
          let lastID;
          let lastNumber;
          let nextQuestion;
          let currentQuestion = this.questionResult.questionNumber + this.questionResult.questionSubNumber;
          let counter = 1;
          while (this.questionProgress[this.questionProgress.length - counter].params.questionResult.questionHidden) {
            lastID = this.questionProgress[this.questionProgress.length - counter].questionID;
            lastNumber = this.questionProgress[this.questionProgress.length - counter].questionNumber;
            nextQuestion = this.questionProgress[this.questionProgress.length - counter].questionNumber;
            let newCalc1 = this.calculationData.filter(item => item.key != currentQuestion)
            this.calculationData = newCalc1.filter(item => item.key != lastNumber)
            currentQuestion = nextQuestion
            counter = counter + 1
          }
          lastID = this.questionProgress[this.questionProgress.length - counter].questionID;
          lastNumber = this.questionProgress[this.questionProgress.length - counter].questionNumber;
          let newCalc1 = this.calculationData.filter(item => item.key != currentQuestion)
          this.calculationData = newCalc1.filter(item => item.key != lastNumber)
          // VERVOLGENS HAAL IK DE LAATSTE RIJ VAN DE QUESTIONPROGRESS ERAF. DAARNA SPREEK IK PROCESSQUESTION AAN EN LAAD IK HET LAATSTE ID IN.
          const arr = [...this.questionProgress]
          this.questionProgress.splice(arr.length - counter, counter)
          await this.processQuestion(false, false, false, lastID, null, null, true)
        } else if (multiple == true) {
          // ALS ER MEERDERE VRAGEN MOETEN WORDEN VERWIJDERD.
          // LAATSTE ID IS DE INDEX VAN DE VRAAG DIE AAN IS GEKLIKT UIT DE PROGRESS.
          let amountIndex = ((this.questionProgress.length) - index)
          let loopIndex = this.questionProgress.length - amountIndex
          const lastID = this.questionProgress[index].questionID;
          const lastKey = this.questionProgress[index].questionNumber;

          this.futureResultNumber = this.questionProgress.find(obj => obj.questionNumber == lastKey).futureResultNr

          let newCalc1 = []

          for (let i = 0; i < loopIndex; i++) {
            this.calculationData.find(obj => obj.key == this.questionProgress[i].questionNumber) != undefined ? newCalc1.push(this.calculationData.find(obj => obj.key == this.questionProgress[i].questionNumber)) : null
          }
          this.calculationData = newCalc1
          this.questionProgress.splice(index, amountIndex);
          await this.processQuestion(false, false, false, lastID, null, null, true)
        }
        let setFutureResultNr = this.questionProgress.length > 1 ? this.questionProgress[this.questionProgress.length - 1].futureResultNumber : null
        setFutureResultNr != undefined && setFutureResultNr != null ? this.futureResultNumber = setFutureResultNr : this.futureResultNumber = null
      }
    },

    // vraag toevoegen aan questionProgress. Wel wat spaghetticode maar het doet zijn werk
    // answer = answerData
    // total = questionResult

    addToProgression(answer, total, inputFields, params) {
      // ALS HET GEEN TOELICHTING, MEERKEUZE OF INVOERVELDEN ZIJN.
      let questionN = total.questionNumber + total.questionSubNumber
      if (questionN == '1B') {
        this.firstQuestion = false
        this.currentTest[0].test_name = answer.answerText
        this.currentTest[0].test_status = 'lopend'
      }
      this.questionProgress = this.questionProgress.filter(item => item.questionNumber !== questionN);

      if (total.answerType != '7' && total.answerType != '8' && total.answerType != '5') {
        const obj = {
          questionID: total.id,
          questionNumber: (total.questionNumber + total.questionSubNumber),
          answerType: total.answerType,
          questionName: total.questionName,
          questionAnswer: answer.answerText,
          commentary: answer.addCommentary == '1' ? answer.commentary : false,
          answerValue: round(answer.answerValue, 2).toFixed(2),
          typeOf: typeof answer.answerText,
          futureResultNr: this.futureResultNumber,
          extraInfo: answer.extraInfo,
          params: params
        }
        this.questionProgress.push(obj);
      }
      // ALS HET MEERKEUZE IS.
      if (total.answerType == '7') {
        const answerArr = []
        let totalValue = 0
        // LOOPEN DOOR SELECTEDANSWERS
        // ANSWERARRAY VULLEN MET DE INGEVULDE ANSWERTEXTS
        // TOTALEVALUE VALUE OPTELLEN ADHV ANSWERVALUES
        for (let i = 0; i < this.selectedAnswers.length; i++) {
          answerArr.push(this.selectedAnswers[i].answerText)
          totalValue += Number(this.selectedAnswers[i].answerValue)
        }
        const answerString = answerArr.toString();
        const obj = {
          questionID: total.id,
          questionNumber: (total.questionNumber + total.questionSubNumber),
          answerType: total.answerType,
          questionName: total.questionName,
          questionAnswer: answerString,
          answerValue: totalValue,
          extraInfo: answer.extraInfo,
          params: params
        }
        this.questionProgress.push(obj);
      }
      // ALS HET INPUTFIELDS ZIJN. DE TOTALE VALUE VAN ALLE INPUTFIELDS WORDT TOEGEWEZEN AAN TOTALVALUE.
      if (total.answerType == '8') {
        let totalValue = 0
        for (let i = 0; i < this.inputFields.length; i++) {
          totalValue += Number(this.inputFields[i])
        }
        let totalFormattedValue = this.formatZeros(String(totalValue), false)
        const obj = {
          questionID: total.id,
          questionNumber: (total.questionNumber + total.questionSubNumber),
          answerType: total.answerType,
          questionName: total.questionName,
          inputFieldsArr: inputFields,
          questionAnswer: total.answerQuestion[0].noNumber == "1" ? (this.inputFields.join(',')).toString() : totalValue,
          typeOf: typeof totalValue,
          answerValue: totalFormattedValue,
          extraInfo: answer.extraInfo,
          params: params
        }
        this.questionProgress.push(obj);
      }

      if (total.answerType == '5') {
        // let answer
        let answer = this.selectedAnswer.answerText
        // if (this.selectedAnswer.answerText.includes('-')) {
        //   answer = this.selectedAnswer.answerText
        // } else {
        //   answer = Number(this.selectedAnswer.answerText)
        // }
        const obj = {
          questionID: total.id,
          questionNumber: (total.questionNumber + total.questionSubNumber),
          answerType: total.answerType,
          questionName: total.questionName,
          inputFieldsArr: inputFields,
          questionAnswer: answer,
          typeOf: typeof answer,
          extraInfo: answer.extraInfo,
          answerValue: this.selectedAnswer.answerText,
          params: params
        }
        this.questionProgress.push(obj);
      }
      // Als het vraag 1A is voeg ik ook de userID van auth0 toe,
      if (questionN == '1A') {
      }
      this.updateLocalStorage()
      this.scrollToBottom();
    },

    async updateLocalStorage() {
      if (this.currentTest) {
        this.currentTest[0].test_json = {
          questionProgress: JSON.stringify(this.questionProgress),
          calculationData: JSON.stringify(this.calculationData)
        }
        const requestOptions = {
          method: "PUT",
          headers: {"Content-Type": "application/json"},
          body: JSON.stringify(this.currentTest[0]),
        };
        const response = await fetch("https://nodejs.imaginecreativeagency.nl/dividenduitkering-live/tests/index.php", requestOptions);
        return await response.json();
      }
    },
    // REDIRECTEN NAAR EEN RESULT MET HET MEEGEGEVEN RESULTID
    openModalPart2() {
      $('#part2Modal').modal('show');
    },

    // Token genereren voor DB insertion
    generateToken() {
      return Math.random().toString(36).substring(2, 10);
    },

    // Toets afronden
    async completeTest() {
      window.removeEventListener('beforeunload', this.showWindow)
      this.showWarning = false
      this.questionLoading = true
      this.addToProgression(this.selectedAnswer, this.questionResult, this.inputFields)
      this.createPDFToServer(true)

      // let token = this.generateToken()
      //
      // async function insertData() {
      //   let getQARr =
      //       {
      //         selectAll: "true",
      //         from: "drv_finishedTests",
      //         testQuery: "false",
      //         where: {
      //           st1: `generatedToken = '${token}'`,
      //         },
      //       }
      //   const requestOptions = {
      //     method: "POST",
      //     headers: {"Content-Type": "application/json"},
      //     body: JSON.stringify(getQARr),
      //   };
      //   const response = await fetch("https://nodejs.imaginecreativeagency.nl/dividenduitkering-live/dynamicGet/index.php", requestOptions);
      //   return await response.json();
      // }
      //
      // let data = await insertData()
      // if (data['data'] != false) {
      //   await insertData()
      // }
      // else {
      //   const currentTimestamp = new Date();
      //   const formattedTimestamp = currentTimestamp.toISOString(); // This is in ISO 8601 format
      //
      //   //inserten van data in finishedTests table
      //   let testArray =
      //       {
      //         tableName: "drv_finishedTests",
      //         columns:
      //             {
      //               generatedToken: token,
      //               userID: this.questionProgress.find(obj => obj.questionNumber == '1A').userID == undefined ? this.user.sub : this.questionProgress.find(obj => obj.questionNumber == '1A').userID
      //               ,
      //               questionProgress: JSON.stringify(this.questionProgress).replace(/\"/g, '\\"'),
      //               // questionProgress: JSON.stringify(this.questionProgress),
      //               calculationData: JSON.stringify(this.calculationData),
      //               finishedDate: formattedTimestamp
      //             },
      //       }
      //   const requestOptions = {
      //     method: "POST",
      //     headers: {"Content-Type": "application/json"},
      //     body: JSON.stringify(testArray)
      //   };
      //
      //   const response = await fetch("https://nodejs.imaginecreativeagency.nl/dividenduitkering-live/dynamicInsert/index.php", requestOptions);
      //   const answerInsertResp = await response.json();
      //   if (answerInsertResp['success'] == true) {
      //     const requestOptions = {
      //       method: "POST",
      //       headers: {"Content-Type": "application/json"},
      //       body: JSON.stringify(testArray)
      //     };
      //     const response2 = await fetch("https://nodejs.imaginecreativeagency.nl/dividenduitkering-live/dynamicInsert/index.php", requestOptions);
      //
      //     // const storage = localStorage.getItem('drv_tests')
      //     if(response2['success'] === true){
      //       this.$router.push("/overzicht");
      //     }
      //   }
      // }
    },

    async createPDFToServer() {
      if (!this.createdPDF) {
        let params = {
          "firstLoad": true,
          "useCalculation": false,
          "customQNumber": true,
          "questionID": '201',
          "subID": false,
          "answerData": null,
          "questionResult": null,
          "result": null,
          "subCalculation": false,
        }
        this.addToProgression(this.selectedAnswer, this.questionResult, this.inputFields, params)
        this.createdPDF = true;
      }
      const requestOptions = {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify(this.questionProgress),
      };
      const response = await fetch("https://nodejs.imaginecreativeagency.nl/dividenduitkering-live/pdf/store_progression_for_pdf.php", requestOptions);
      const data = await response.json();
      if (data && this.questionResult.questionNumber < 202) {
        window.open(`https://nodejs.imaginecreativeagency.nl/dividenduitkering-live/pdf/tcpdf.php?filename=${data.data}`, '_blank');
      } else if (data) {
        if (data) {
          const sendMailObj = {
            'email_address': this.currentTest[0].user_email,
            'filename': data.data,
            'intern': this.currentTest[0].user_intern
          }
          const requestOptions2 = {
            method: "POST",
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify(sendMailObj),
          };
          const response2 = await fetch("https://nodejs.imaginecreativeagency.nl/dividenduitkering-live/pdf/tcpdf.php", requestOptions2);
          const data2 = response2.json()
          if (data2) {
            const obj3 = {
              'test_unique_id': this.$route.params.id,
              'filename': data.data
            }
            const requestOptions3 = {
              method: "DELETE",
              headers: {"Content-Type": "application/json"},
              body: JSON.stringify(obj3),
            };
            const response3 = await fetch("https://nodejs.imaginecreativeagency.nl/dividenduitkering-live/tests/index.php", requestOptions3);
            const data3 = response3.json()
            if (data3) {
              this.$router.push("/overzicht");
            }
          }

        }
      }


    },

    // Deel 1 afsluiten en activePartTwo
    // aanzetten. Hierdoor worden vorige vragen ontoegankelijk
    async closePartOne() {
      if (this.questionResult.questionNumber != 72) {
        let params = {
          "firstLoad": true,
          "useCalculation": false,
          "customQNumber": true,
          "questionID": '201',
          "subID": false,
          "answerData": null,
          "questionResult": null,
          "result": null,
          "subCalculation": false,
        }
        this.addToProgression(this.selectedAnswer, this.questionResult, this.inputFields, params)
      }
      this.activePartTwo = true
      await this.processQuestion(true, false, true, '201', false, null, null, false)
    },

    // Als het resultID afsluiten is dan naar einde toets en anders naar deel 2
    redirectToResult(resultID) {
      if (resultID) {
        if (resultID == '1') {
          if (this.questionResult.questionNumber == 72) {
            let params = {
              "firstLoad": false,
              "useCalculation": false,
              "customQNumber": false,
              "questionID": 201,
              "subID": false,
              "answerData": this.selectedAnswer,
              "questionResult": this.questionResult,
              "result": false,
              "subCalculation": false,
            }
            this.addToProgression(this.selectedAnswer, this.questionResult, this.inputFields, params)
          }
          this.openModalPart2()
        } else {
          $('#endPart2Modal').modal('show');
        }
      }
    },

    // naberekenen van vragen
    calculateAfterInput(id, subID) {
      let q14 = this.questionProgress.find(obj => obj.questionNumber == '14') != undefined ? this.questionProgress.find(obj => obj.questionNumber == '14') : undefined

      if (id == '16') {
        if (q14 != undefined) {
          this.processQuestion(false, false, false, this.nextID.nextID, false, null, null, true)
        } else {
          this.loadErrorScreen()
        }
      } else if (id == '51') {
        let totalCapital
        let loanCapital
        let solvencyRatio
        let controlCalculation
        let workingCapital
        let q51A = this.questionProgress.find(obj => obj.questionNumber == '51A') != undefined ? this.questionProgress.find(obj => obj.questionNumber == '51A') : undefined
        let q51B = this.questionProgress.find(obj => obj.questionNumber == '51B') != undefined ? this.questionProgress.find(obj => obj.questionNumber == '51B') : undefined
        if (id == '51' && subID == 'A') {
          totalCapital = q51A.questionAnswer
          loanCapital = totalCapital - q14.questionAnswer
          if (q51A != undefined) {
            const item51A = this.calculationData.find(i => i.key == '51A');
            if (!item51A) {
              this.calculationData.push({
                key: '51A', totalCapital: totalCapital, loanCapital: loanCapital, answerArray: q51A.inputFieldsArr
              });
            } else {
              item51A.totalCapital = totalCapital
              item51A.loanCapital = loanCapital
              item51A.answerArray = q51A.inputFieldsArr
            }
            this.updateLocalStorage()
          }
          this.processQuestion(false, false, false, this.nextID.nextID, false, null, null, true)
        }
        if (id == '51' && subID == 'B') {
          let q51AData = this.calculationData.find(obj => obj.key == '51A') != undefined ? this.calculationData.find(obj => obj.key == '51A') : this.loadingError = true
          controlCalculation = q51AData.loanCapital - q51B.questionAnswer
          solvencyRatio = this.questionProgress.find(obj => obj.questionNumber == '14').questionAnswer / q51AData.totalCapital
          solvencyRatio = solvencyRatio * 100
          workingCapital = parseFloat(q51AData.answerArray[3]) + parseFloat(q51AData.answerArray[4]) + parseFloat(q51AData.answerArray[5]) - parseFloat(q51B.inputFieldsArr[2])

          const item51B = this.calculationData.find(i => i.key == '51B')
          if (!item51B) {
            this.calculationData.push({
              key: '51B',
              controlCalculation: controlCalculation,
              solvencyRatio: Math.round(solvencyRatio),
              workingCapital: workingCapital,
              answerArray: q51B.inputFieldsArr
            });
          } else {
            item51B.controlCalculation = controlCalculation
            item51B.solvencyRatio = Math.round(solvencyRatio)
            item51B.workingCapital = workingCapital
            item51B.answerArray = q51B.inputFieldsArr
          }
          this.updateLocalStorage()
          this.processQuestion(false, false, false, this.nextID.nextID, false, null, null, true)
        }
      } else {
        this.processQuestion(false, false, false, this.nextID.nextID, false, null, null, true)
      }
    },

    async loadErrorScreen() {
      this.questionLoading = true
      await this.sleep(500);
      this.questionLoading = false
      this.isData = false
      this.loadingError = true
    },

    async processQuestion(firstLoad, useCalculation, customQNumber, questionID, subID, answerData, questionResult,
                          result, subCalculation) {
      if (
          firstLoad && (
              questionResult?.questionNumber == 16 ||
              questionResult?.questionNumber == 51 ||
              questionID == 467 ||
              questionID == 614 ||
              questionID == 613
          )
      ) {
        customQNumber = false;
      }
      let params = {
        "firstLoad": firstLoad,
        "useCalculation": useCalculation,
        "customQNumber": customQNumber,
        "questionID": questionID,
        "subID": subID,
        "answerData": answerData,
        "questionResult": questionResult,
        "result": result,
        "subCalculation": subCalculation,
      }
      // ALS HET ID LEEG IS DAN ERROR WEERGEVEN //
      if (questionID == null || questionID == '' || questionID == undefined) {
        await this.loadErrorScreen()
      }

      // BIJ DE EERSTE KEER GELIJK DATA INLADEN.
      // ANDERS KIJKEN OF DE CALCULATIE AAN STAAT EN DAN DE VRAAG TOEVOEGEN AAN DE PROGRESSIE EN
      // DOORVERWIJZEN NAAR DE CALCULATIE
      // ALS DE CALCULATIE UIT STAAT DAN
      if (firstLoad == true) {
        await this.loadQuestionData(firstLoad, useCalculation, customQNumber, questionID, subID)
      } else {
        this.firstLoad = false
        if (useCalculation == true && questionResult.answerType != '9' && questionResult.answerType != '10') {
          this.addToProgression(answerData, questionResult, this.inputFields, params)
          this.calculateAfterInput(questionResult.questionNumber, questionResult.questionSubNumber)
        } else if (useCalculation == true && questionResult.answerType == '9' && questionResult.answerType != '10') {
          this.addToProgression(answerData, questionResult, this.inputFields, params)
        } else {
          await this.loadQuestionData(firstLoad, useCalculation, customQNumber, questionID, subID, answerData,
              questionResult, result, subCalculation)
        }
      }
    },

    async loadQuestionData(firstLoad, useCalculation, customQNumber, questionID, subID, answerData, questionRes, result, subCalc) {
      let params = {
        "firstLoad": firstLoad,
        "useCalculation": useCalculation,
        "customQNumber": customQNumber,
        "questionID": questionID,
        "subID": subID,
        "answerData": answerData,
        "questionResult": questionRes,
        "result": result,
        "subCalculation": subCalc,
      }
      if (firstLoad == false && result == false || subCalc == true) {
        this.addToProgression(answerData, questionRes, this.inputFields, params)
      }

      this.resetData()
      let dataArr = []
      if (customQNumber == true) {
        let flArray =
        {
          selectAll: "true",
          from: "questions",
          where: {
            st1: "questions.questionNumber = " + questionID,
          },
          orderBy: "questions.questionNumber ASC, subID ASC",
          testQuery: "false",
        }
        let flArray2 =
        {
          selectAll: "true",
          from: "questions",
          where: {
            st1: "questions.questionNumber = " + questionID,
            cond1: "AND",
            st2: "questions.subID = '" + subID + "'"
          },
          orderBy: "questions.questionNumber ASC, subID ASC",
          testQuery: "false",
        }
        const requestOptions2 = {
          method: "POST",
          headers: {"Content-Type": "application/json"},
          body: JSON.stringify(subID != false ? flArray2 : flArray),
        };
        const response = await fetch("https://nodejs.imaginecreativeagency.nl/dividenduitkering-live/dynamicGet/index.php", requestOptions2);
        dataArr = await response.json();
      } else if (customQNumber == false || result == true) {
        this.customQNumber = false
        let resultArr1 =
            {
              selectAll: "true",
              from: "questions",
              where: {
                st1: "ID = " + questionID,
              },
              orderBy: "questions.questionNumber ASC, subID ASC",
              testQuery: "false",
            }
        const requestOptions1 = {
          method: "POST",
          headers: {"Content-Type": "application/json"},
          body: JSON.stringify(resultArr1),
        };

        const response = await fetch("https://nodejs.imaginecreativeagency.nl/dividenduitkering-live/dynamicGet/index.php", requestOptions1);
        dataArr = await response.json();
      }

      if (dataArr['success'] == true && dataArr['data'] != false) {
        this.questionResult = dataArr['data'];
        for (let i = 0; i < this.questionResult.length; i++) {
          const requestOptions = {
            method: "POST",
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify(this.questionResult[i])
          };
          const response = await fetch("https://nodejs.imaginecreativeagency.nl/dividenduitkering-live/answers/get.php", requestOptions);
          const answData = await response.json();
          if (answData.length) {
            let questionArray =
                {
                  id: this.questionResult[i].ID,
                  questionNumber: this.questionResult[i].questionNumber,
                  questionSubNumber: this.questionResult[i].subID,
                  questionName: this.questionResult[i].questionName,
                  questionDesc: this.questionResult[i].questionDesc,
                  questionTitle: this.questionResult[i].questionTitle,
                  questionInfo: this.questionResult[i].questionInfo,
                  answerType: this.questionResult[i].answerType,
                  useInputFields: this.questionResult[i].useInputFields,
                  requireAllFields: this.questionResult[i].requireAllFields,
                  checkSelectedAnswer: this.questionResult[i].checkSelectedAnswer,
                  useFormula: this.questionResult[i].useFormula,
                  questionHidden: this.questionResult[i].questionHidden,
                  multipleChoice: [],
                  answerQuestion: [],
                }
            let dataCount = 0
            let succesCount = 0
            questionArray.answerType == '2' || questionArray.answerType == '10' || questionArray.useFormula == '1' || questionArray.questionNumber == '71' ? await this.prefilledAnswer(questionArray.useFormula, questionArray.questionNumber, questionArray.questionSubNumber) : null
            if (questionArray.questionNumber == ('55' || '56' || '57' || '58' || '59' || '60' || '61' || '62' || '63'
                || '64' || '65' || '66' || '67' || '68')) {
              this.redirectedQuestionSub = null
              this.redirectedQuestionNumb = null
            }
            for (let i = 0; i < answData.length; i++) {
              dataCount++
              let linkedAnswerID = answData[i].answerID
              let linkedArr =
                  {
                    selectAll: "true",
                    from: "linkAnswers",
                    where: {
                      st1: "linkedAnswerID = " + linkedAnswerID,
                    },
                    testQuery: "false",
                  }

              const requestOptions1 = {
                method: "POST",
                headers: {"Content-Type": "application/json"},
                body: JSON.stringify(linkedArr),
              };


              const response = await fetch("https://nodejs.imaginecreativeagency.nl/dividenduitkering-live/dynamicGet/index.php", requestOptions1);
              dataArr = await response.json();
              if (dataArr['success'] == true && dataArr['data'] != false) {
                succesCount++
                let nextQuestionID = dataArr['data'][0].linkedQuestionID
                let nextResultID = dataArr['data'][0].linkedResultID
                let linkType = dataArr['data'][0].linkType
                let selectedAnswer = {}

                if (questionArray.answerType == '1' || questionArray.answerType == '2' || questionArray.answerType == '4'
                    || questionArray.answerType == '7') {
                  this.currentQuestionType = 0
                  if (i == answData.length - 1) {
                    questionArray.multipleChoice.push({
                      answerID: answData[i].answerID,
                      answerText: answData[i].answerName,
                      answerRequired: answData[i].answerRequired,
                      questionID: answData[i].questionID,
                      extraInfo: answData[i].extraInfo,
                      answerValue: answData[i].answerValue,
                      nextQuestionID: nextQuestionID,
                      addCommentary: answData[i].addCommentary,
                      nextResult: nextResultID,
                      linkType: linkType
                    });
                  } else {
                    questionArray.multipleChoice.push({
                      answerID: answData[i].answerID,
                      answerText: answData[i].answerName,
                      answerRequired: answData[i].answerRequired,
                      questionID: answData[i].questionID,
                      extraInfo: answData[i].extraInfo,
                      answerValue: answData[i].answerValue,
                      addCommentary: answData[i].addCommentary,
                      nextQuestionID: nextQuestionID,
                      nextResult: nextResultID,
                      linkType: linkType
                    });
                  }
                } else if (questionArray.answerType == '3' || questionArray.answerType == '5' || questionArray.answerType == '6'
                    || questionArray.answerType == '8' || questionArray.answerType == '9') {
                  this.currentQuestionType = 1
                  if (questionArray.answerType != '8' && questionArray.answerType != '9') {
                    questionArray.answerQuestion.push({
                      answerID: answData[i].answerID,
                      answerText: answData[i].answerName,
                      questionID: answData[i].questionID,
                      answerValue: answData[i].answerValue,
                      answerRequired: answData[i].answerRequired,
                      questionLbl: answData[i].questionLbl,
                      positiveValue: answData[i].positiveValue,
                      extraInfo: answData[i].extraInfo,
                      nextQuestionID: nextQuestionID,
                      nextResult: nextResultID,
                      linkType: linkType,
                    });
                  }
                  if (questionArray.answerType == '8') {
                    questionArray.answerQuestion.push({
                      answerID: answData[i].answerID,
                      answerName: answData[i].answerName,
                      answerText: "",
                      answerRequired: answData[i].answerRequired,
                      questionID: answData[i].questionID,
                      answerValue: answData[i].answerValue,
                      questionLbl: answData[i].questionLbl,
                      noNumber: answData[i].noNumber,
                      positiveValue: answData[i].positiveValue,
                      extraInfo: answData[i].extraInfo,
                      nextQuestionID: nextQuestionID,
                      nextResult: nextResultID,
                      linkType: linkType,
                    });
                  }
                  if (questionArray.answerType == '9') {
                    questionArray.answerQuestion.push({
                      answerID: answData[i].answerID,
                      answerName: answData[i].answerName,
                      answerText: "",
                      answerRequired: answData[i].answerRequired,
                      questionID: answData[i].questionID,
                      answerValue: answData[i].answerValue,
                      questionLbl: answData[i].questionLbl,
                      noNumber: answData[i].noNumber,
                      extraInfo: answData[i].extraInfo,
                      positiveValue: answData[i].positiveValue,
                      calculatedAns: answData[i].calculatedAns,
                      nextQuestionID: nextQuestionID,
                      nextResult: nextResultID,
                      linkType: linkType,
                    });
                  }
                  this.inputFieldCount = questionArray.answerQuestion.length
                  selectedAnswer.nextQuestionID = nextQuestionID
                  selectedAnswer.nextResult = nextResultID
                  selectedAnswer.linkType = linkType
                  selectedAnswer.answerValue = answData[i].answerValue
                  selectedAnswer.extraInfo = answData[i].extraInfo
                  selectedAnswer.answerText = ""
                  this.selectedAnswer = selectedAnswer
                }
              }
            }
            if (dataCount == succesCount) {
              this.currentQuestionID = questionArray.id
              this.questionResult = questionArray
              this.isData = true
              // this.sleep(500)
              this.questionLoading = false
              const qNum = questionArray.questionNumber
              const qSubNum = questionArray.questionSubNumber
              if (qNum + qSubNum == '71C' || qNum + qSubNum == '105' || qNum + qSubNum == '135') {
                this.redirectConclusion(qNum, qSubNum)
              } else {
                questionArray.answerType == '9' ? await this.prefilledAnswer(questionArray.useFormula, questionArray.questionNumber,
                    questionArray.questionSubNumber) : null
                questionArray.answerType == '6' && questionArray.useFormula == '1' ? await this.prefilledAnswer(questionArray.useFormula,
                    questionArray.questionNumber, questionArray.questionSubNumber) : null
              }
            } else if (dataCount != succesCount) {
              this.loadingError = true
            }
          }
        }
        if (this.questionResult.questionHidden) {
          this.processQuestion(false, false, false, this.questionResult.answerQuestion[0].nextQuestionID, null, this.selectedAnswer, this.questionResult, false, false)
        }
      } else {
        this.isData = false
        this.loadingError = true
      }
    },

    redirectConclusion(id, subID) {
      if (id + subID == '71C') {
        let ans34D = this.calculationData.find(obj => obj.key == '34D') != undefined ? this.calculationData.find(obj => obj.key == '34D').answerData : undefined
        let ans35C = this.calculationData.find(obj => obj.key == '35C') != undefined ? this.calculationData.find(obj => obj.key == '35C').answerData : undefined
        let ans54A = this.calculationData.find(obj => obj.key == '54A') != undefined ? this.calculationData.find(obj => obj.key == '54A').answerData : undefined
        let ans54C = this.calculationData.find(obj => obj.key == '54C') != undefined ? this.calculationData.find(obj => obj.key == '54C').answerData : undefined
        let ans54D = this.calculationData.find(obj => obj.key == '54D') != undefined ? this.calculationData.find(obj => obj.key == '54D').answerData : undefined
        let ans54E = this.calculationData.find(obj => obj.key == '54E') != undefined ? this.calculationData.find(obj => obj.key == '54E').answerData : undefined
        let ans55 = this.questionProgress.find(obj => obj.questionNumber == '55') != undefined ? this.questionProgress.find(obj => obj.questionNumber == '55').extraInfo : undefined
        let ans56 = this.questionProgress.find(obj => obj.questionNumber == '56') != undefined ? this.questionProgress.find(obj => obj.questionNumber == '56').extraInfo : undefined
        let ans58 = this.questionProgress.find(obj => obj.questionNumber == '58') != undefined ? this.questionProgress.find(obj => obj.questionNumber == '58').extraInfo : undefined
        let ans59 = this.questionProgress.find(obj => obj.questionNumber == '59') != undefined ? this.questionProgress.find(obj => obj.questionNumber == '59').extraInfo : undefined
        let ans60 = this.questionProgress.find(obj => obj.questionNumber == '60') != undefined ? this.questionProgress.find(obj => obj.questionNumber == '60').extraInfo : undefined
        let ans61 = this.questionProgress.find(obj => obj.questionNumber == '61') != undefined ? this.questionProgress.find(obj => obj.questionNumber == '61').extraInfo : undefined
        let ans62 = this.questionProgress.find(obj => obj.questionNumber == '62') != undefined ? this.questionProgress.find(obj => obj.questionNumber == '62').extraInfo : undefined
        let ans63 = this.questionProgress.find(obj => obj.questionNumber == '63') != undefined ? this.questionProgress.find(obj => obj.questionNumber == '63').extraInfo : undefined
        let ans64 = this.questionProgress.find(obj => obj.questionNumber == '64') != undefined ? this.questionProgress.find(obj => obj.questionNumber == '64').extraInfo : undefined
        let ans65 = this.questionProgress.find(obj => obj.questionNumber == '65') != undefined ? this.questionProgress.find(obj => obj.questionNumber == '65').extraInfo : undefined
        let ans66 = this.questionProgress.find(obj => obj.questionNumber == '66') != undefined ? this.questionProgress.find(obj => obj.questionNumber == '66').extraInfo : undefined
        let ans67 = this.questionProgress.find(obj => obj.questionNumber == '67') != undefined ? this.questionProgress.find(obj => obj.questionNumber == '67').extraInfo : undefined
        let ans68 = this.questionProgress.find(obj => obj.questionNumber == '68') != undefined ? this.questionProgress.find(obj => obj.questionNumber == '68').extraInfo : undefined
        let ans71A = this.questionProgress.find(obj => obj.questionNumber == '71A') != undefined ? this.questionProgress.find(obj => obj.questionNumber == '71A').extraInfo : undefined
        let nextQuestion;
        if (ans54A < 0 || ans54C < 0 || ans67?.toLowerCase() == 'nee') {
          nextQuestion = '77';
        } else if (
            Number(ans34D) < 0 ||
            Number(ans35C) < 0 ||
            Number(ans54E) < 1 ||
            Number(ans54D) < 1.5 ||
            ans55?.toLowerCase() === 'q79' ||
            ans56?.toLowerCase() === 'q79' ||
            ans58?.toLowerCase() === 'q79' ||
            ans59?.toLowerCase() === 'q79' ||
            ans60?.toLowerCase() === 'q79' ||
            ans61?.toLowerCase() === 'q79' ||
            ans62?.toLowerCase() === 'q79' ||
            ans63?.toLowerCase() === 'q79' ||
            ans64?.toLowerCase() === 'q79' ||
            ans65?.toLowerCase() === 'q79' ||
            ans66?.toLowerCase() === 'q79' ||
            ans68?.toLowerCase() === 'q79' ||
            ans71A?.toLowerCase() === 'q79'
        ) {
          nextQuestion = "79";
        } else {
          nextQuestion = "75";
        }
        this.processQuestion(false, false, true, nextQuestion, 'A', null, null, true)
      } else if (id == '105') {
        let ans83G = this.calculationData.find(obj => obj.key == '83G') != undefined ? this.calculationData.find(obj => obj.key == '83G').answerData : undefined
        let ans83J = this.questionProgress.find(obj => obj.questionNumber == '83J') != undefined ? this.questionProgress.find(obj => obj.questionNumber == '83J').questionAnswer : undefined
        let ans87A = this.questionProgress.find(obj => obj.questionNumber == '87A') != undefined ? this.questionProgress.find(obj => obj.questionNumber == '87A').questionAnswer : undefined
        let ans87C = this.calculationData.find(obj => obj.key == '87C') != undefined ? this.calculationData.find(obj => obj.key == '87C').answerData : undefined
        let ans87D = this.calculationData.find(obj => obj.key == '87D') != undefined ? this.calculationData.find(obj => obj.key == '87D').answerData : undefined
        let ans87E = this.calculationData.find(obj => obj.key == '87E') != undefined ? this.calculationData.find(obj => obj.key == '87E').answerData : undefined
        let ans88 = this.questionProgress.find(obj => obj.questionNumber == '88') != undefined ? this.questionProgress.find(obj => obj.questionNumber == '88').extraInfo : undefined
        let ans90 = this.questionProgress.find(obj => obj.questionNumber == '90') != undefined ? this.questionProgress.find(obj => obj.questionNumber == '90').extraInfo : undefined
        let ans91 = this.questionProgress.find(obj => obj.questionNumber == '91') != undefined ? this.questionProgress.find(obj => obj.questionNumber == '91').extraInfo : undefined
        let ans92 = this.questionProgress.find(obj => obj.questionNumber == '92') != undefined ? this.questionProgress.find(obj => obj.questionNumber == '92').extraInfo : undefined
        let ans93 = this.questionProgress.find(obj => obj.questionNumber == '93') != undefined ? this.questionProgress.find(obj => obj.questionNumber == '93').extraInfo : undefined
        let ans94 = this.questionProgress.find(obj => obj.questionNumber == '94') != undefined ? this.questionProgress.find(obj => obj.questionNumber == '94').extraInfo : undefined
        let ans95 = this.questionProgress.find(obj => obj.questionNumber == '95') != undefined ? this.questionProgress.find(obj => obj.questionNumber == '95').extraInfo : undefined
        let ans96 = this.questionProgress.find(obj => obj.questionNumber == '96') != undefined ? this.questionProgress.find(obj => obj.questionNumber == '96').extraInfo : undefined
        let ans97 = this.questionProgress.find(obj => obj.questionNumber == '97') != undefined ? this.questionProgress.find(obj => obj.questionNumber == '97').extraInfo : undefined
        let ans98B = this.questionProgress.find(obj => obj.questionNumber == '98B') != undefined ? this.questionProgress.find(obj => obj.questionNumber == '98B').extraInfo : undefined
        let ans100 = this.questionProgress.find(obj => obj.questionNumber == '100') != undefined ? this.questionProgress.find(obj => obj.questionNumber == '100').extraInfo : undefined
        let ans101 = this.questionProgress.find(obj => obj.questionNumber == '101') != undefined ? this.questionProgress.find(obj => obj.questionNumber == '101').questionAnswer : undefined
        let ans102 = this.questionProgress.find(obj => obj.questionNumber == '102') != undefined ? this.questionProgress.find(obj => obj.questionNumber == '102').extraInfo : undefined
        let nextQuestion;
        if (ans87A < 0 || ans87C < 0 || ans101?.toLowerCase() == 'nee') {
          nextQuestion = '107'
        } else if (
            Number(ans83G) < 0 ||
            Number(ans83J) < 0 ||
            Number(ans87E) < 1 ||
            Number(ans87D) < 1.5 ||
            ans88?.toLowerCase() === 'q108' ||
            ans90?.toLowerCase() === 'q108' ||
            ans91?.toLowerCase() === 'q108' ||
            ans92?.toLowerCase() === 'q108' ||
            ans93?.toLowerCase() === 'q108' ||
            ans94?.toLowerCase() === 'q108' ||
            ans95?.toLowerCase() === 'q108' ||
            ans96?.toLowerCase() === 'q108' ||
            ans97?.toLowerCase() === 'q108' ||
            ans98B?.toLowerCase() === 'q108' ||
            ans100?.toLowerCase() === 'q108' ||
            ans102?.toLowerCase() === 'q108'
        ) {
          nextQuestion = "108"
        } else {
          // Vraag 106
          nextQuestion = "106"
        }
        this.processQuestion(false, false, true, nextQuestion, 'A', null, null, true)
      } else if (id + subID == '135') {
        let ans113J = this.questionProgress.find(obj => obj.questionNumber == '113J') != undefined ? this.questionProgress.find(obj => obj.questionNumber == '113J').questionAnswer : undefined
        let ans113G = this.calculationData.find(obj => obj.key == '113G') != undefined ? this.calculationData.find(obj => obj.key == '113G').answerData : undefined
        let ans117A = this.calculationData.find(obj => obj.key == '117A') != undefined ? this.calculationData.find(obj => obj.key == '117A').answerData : undefined
        let ans117C = this.calculationData.find(obj => obj.key == '117C') != undefined ? this.calculationData.find(obj => obj.key == '117C').answerData : undefined
        let ans117D = this.calculationData.find(obj => obj.key == '117D') != undefined ? this.calculationData.find(obj => obj.key == '117D').answerData : undefined
        let ans117E = this.calculationData.find(obj => obj.key == '117E') != undefined ? this.calculationData.find(obj => obj.key == '117E').answerData : undefined
        let ans118 = this.questionProgress.find(obj => obj.questionNumber == '118') != undefined ? this.questionProgress.find(obj => obj.questionNumber == '118').extraInfo : undefined
        let ans120 = this.questionProgress.find(obj => obj.questionNumber == '120') != undefined ? this.questionProgress.find(obj => obj.questionNumber == '120').extraInfo : undefined
        let ans121 = this.questionProgress.find(obj => obj.questionNumber == '121') != undefined ? this.questionProgress.find(obj => obj.questionNumber == '121').extraInfo : undefined
        let ans122 = this.questionProgress.find(obj => obj.questionNumber == '122') != undefined ? this.questionProgress.find(obj => obj.questionNumber == '122').extraInfo : undefined
        let ans123 = this.questionProgress.find(obj => obj.questionNumber == '123') != undefined ? this.questionProgress.find(obj => obj.questionNumber == '123').extraInfo : undefined
        let ans124 = this.questionProgress.find(obj => obj.questionNumber == '124') != undefined ? this.questionProgress.find(obj => obj.questionNumber == '124').extraInfo : undefined
        let ans125 = this.questionProgress.find(obj => obj.questionNumber == '125') != undefined ? this.questionProgress.find(obj => obj.questionNumber == '125').extraInfo : undefined
        let ans126 = this.questionProgress.find(obj => obj.questionNumber == '126') != undefined ? this.questionProgress.find(obj => obj.questionNumber == '126').extraInfo : undefined
        let ans127 = this.questionProgress.find(obj => obj.questionNumber == '127') != undefined ? this.questionProgress.find(obj => obj.questionNumber == '127').extraInfo : undefined
        let ans128B = this.questionProgress.find(obj => obj.questionNumber == '128B') != undefined ? this.questionProgress.find(obj => obj.questionNumber == '128B').extraInfo : undefined
        let ans130 = this.questionProgress.find(obj => obj.questionNumber == '130') != undefined ? this.questionProgress.find(obj => obj.questionNumber == '130').extraInfo : undefined
        let ans131 = this.questionProgress.find(obj => obj.questionNumber == '131') != undefined ? this.questionProgress.find(obj => obj.questionNumber == '131').extraInfo : undefined
        let ans132 = this.questionProgress.find(obj => obj.questionNumber == '132') != undefined ? this.questionProgress.find(obj => obj.questionNumber == '132').extraInfo : undefined
        let nextQuestion;
        if (ans117A < 0 || ans117C < 0 || ans131?.toLowerCase() == 'nee') {
          nextQuestion = '137'
        } else if (
            Number(ans113G) < 0 ||
            Number(ans113J) < 0 ||
            Number(ans117E) < 1 ||
            Number(ans117D) < 1.5 ||
            ans118?.toLowerCase() === 'q138' ||
            ans120?.toLowerCase() === 'q138' ||
            ans121?.toLowerCase() === 'q138' ||
            ans122?.toLowerCase() === 'q138' ||
            ans123?.toLowerCase() === 'q138' ||
            ans124?.toLowerCase() === 'q138' ||
            ans125?.toLowerCase() === 'q138' ||
            ans126?.toLowerCase() === 'q138' ||
            ans127?.toLowerCase() === 'q138' ||
            ans128B?.toLowerCase() === 'q138' ||
            ans130?.toLowerCase() === 'q138' ||
            ans132?.toLowerCase() === 'q138'
        ) {
          nextQuestion = "138"
        } else {
          nextQuestion = "136"
        }
        this.processQuestion(false, false, true, nextQuestion, 'A', null, null, true)
      }
    },

    async prefilledAnswer(calc, answernumber, ansSub) {
      let loanCapital = this.calculationData.find(obj => obj.key == '51A') != undefined ? this.calculationData.find(obj => obj.key == '51A').loanCapital : undefined
      let ans2 = this.questionProgress.find(obj => obj.questionNumber == '2') != undefined ? this.questionProgress.find(obj => obj.questionNumber == '2').answerValue : undefined
      let ans4 = this.questionProgress.find(obj => obj.questionNumber == '4') != undefined ? this.questionProgress.find(obj => obj.questionNumber == '4').questionAnswer : undefined
      let ans14 = this.questionProgress.find(obj => obj.questionNumber == '14') != undefined ? this.questionProgress.find(obj => obj.questionNumber == '14').questionAnswer : undefined
      let ans16 = this.questionProgress.find(obj => obj.questionNumber == '16') != undefined ? this.questionProgress.find(obj => obj.questionNumber == '16').questionAnswer : undefined
      let ans17 = this.calculationData.find(obj => obj.key == '17') != undefined ? this.calculationData.find(obj => obj.key == '17').answerData : undefined
      let ans23 = this.questionProgress.find(obj => obj.questionNumber == '23') != undefined ? this.questionProgress.find(obj => obj.questionNumber == '23').questionAnswer : undefined
      let ans24 = this.questionProgress.find(obj => obj.questionNumber == '24') != undefined ? this.questionProgress.find(obj => obj.questionNumber == '24').questionAnswer : undefined
      let ans27 = this.questionProgress.find(obj => obj.questionNumber == '27') != undefined ? this.questionProgress.find(obj => obj.questionNumber == '27').questionAnswer : undefined
      let ans30 = this.questionProgress.find(obj => obj.questionNumber == '30') != undefined ? this.questionProgress.find(obj => obj.questionNumber == '30').questionAnswer : undefined
      let ans33 = this.questionProgress.find(obj => obj.questionNumber == '33') != undefined ? this.questionProgress.find(obj => obj.questionNumber == '33').questionAnswer : undefined
      let ans34A = this.calculationData.find(obj => obj.key == '34A') != undefined ? this.calculationData.find(obj => obj.key == '34A').answerData : undefined
      let ans34B = this.calculationData.find(obj => obj.key == '34B') != undefined ? this.calculationData.find(obj => obj.key == '34B').answerData : undefined
      let ans34C = this.calculationData.find(obj => obj.key == '34C') != undefined ? this.calculationData.find(obj => obj.key == '34C').answerData : undefined
      let ans34D = this.calculationData.find(obj => obj.key == '34D') != undefined ? this.calculationData.find(obj => obj.key == '34D').answerData : undefined
      let ans35C = this.calculationData.find(obj => obj.key == '35C') != undefined ? this.calculationData.find(obj => obj.key == '35C').answerData : undefined
      let ans51BcontrolCalc = this.calculationData.find(obj => obj.key == '51B') != undefined ? this.calculationData.find(obj => obj.key == '51B').controlCalculation : undefined
      let ans51D = this.calculationData.find(obj => obj.key == '51A') != undefined ? this.calculationData.find(obj => obj.key == '51A').answerArray[3] : undefined
      let ans51E = this.calculationData.find(obj => obj.key == '51A') != undefined ? this.calculationData.find(obj => obj.key == '51A').answerArray[4] : undefined
      let ans51F = this.calculationData.find(obj => obj.key == '51A') != undefined ? this.calculationData.find(obj => obj.key == '51A').answerArray[5] : undefined
      let ans51L = this.calculationData.find(obj => obj.key == '51B') != undefined ? this.calculationData.find(obj => obj.key == '51B').answerArray[2] : undefined
      let ans51N = this.calculationData.find(obj => obj.key == '51B') != undefined ? this.calculationData.find(obj => obj.key == '51B').workingCapital : undefined
      let ans53 = this.questionProgress.find(obj => obj.questionNumber == '53') != undefined ? this.questionProgress.find(obj => obj.questionNumber == '53').questionAnswer : undefined
      let ans54A = this.calculationData.find(obj => obj.key == '54A') != undefined ? this.calculationData.find(obj => obj.key == '54A').answerData : undefined
      let ans54C = this.calculationData.find(obj => obj.key == '54C') != undefined ? this.calculationData.find(obj => obj.key == '54C').answerData : undefined
      let ans54D = this.calculationData.find(obj => obj.key == '54D') != undefined ? this.calculationData.find(obj => obj.key == '54D').answerData : undefined
      let ans54E = this.calculationData.find(obj => obj.key == '54E') != undefined ? this.calculationData.find(obj => obj.key == '54E').answerData : undefined
      let ans55 = this.questionProgress.find(obj => obj.questionNumber == '55') != undefined ? this.questionProgress.find(obj => obj.questionNumber == '55').extraInfo : undefined
      let ans56 = this.questionProgress.find(obj => obj.questionNumber == '56') != undefined ? this.questionProgress.find(obj => obj.questionNumber == '56').extraInfo : undefined
      let ans58 = this.questionProgress.find(obj => obj.questionNumber == '58') != undefined ? this.questionProgress.find(obj => obj.questionNumber == '58').extraInfo : undefined
      let ans59 = this.questionProgress.find(obj => obj.questionNumber == '59') != undefined ? this.questionProgress.find(obj => obj.questionNumber == '59').extraInfo : undefined
      let ans60 = this.questionProgress.find(obj => obj.questionNumber == '60') != undefined ? this.questionProgress.find(obj => obj.questionNumber == '60').extraInfo : undefined
      let ans61 = this.questionProgress.find(obj => obj.questionNumber == '61') != undefined ? this.questionProgress.find(obj => obj.questionNumber == '61').extraInfo : undefined
      let ans62 = this.questionProgress.find(obj => obj.questionNumber == '62') != undefined ? this.questionProgress.find(obj => obj.questionNumber == '62').extraInfo : undefined
      let ans63 = this.questionProgress.find(obj => obj.questionNumber == '63') != undefined ? this.questionProgress.find(obj => obj.questionNumber == '63').extraInfo : undefined
      let ans64 = this.questionProgress.find(obj => obj.questionNumber == '64') != undefined ? this.questionProgress.find(obj => obj.questionNumber == '64').extraInfo : undefined
      let ans65 = this.questionProgress.find(obj => obj.questionNumber == '65') != undefined ? this.questionProgress.find(obj => obj.questionNumber == '65').extraInfo : undefined
      let ans66 = this.questionProgress.find(obj => obj.questionNumber == '66') != undefined ? this.questionProgress.find(obj => obj.questionNumber == '66').extraInfo : undefined
      let ans67 = this.questionProgress.find(obj => obj.questionNumber == '67') != undefined ? this.questionProgress.find(obj => obj.questionNumber == '67').extraInfo : undefined
      let ans68 = this.questionProgress.find(obj => obj.questionNumber == '68') != undefined ? this.questionProgress.find(obj => obj.questionNumber == '68').questionAnswer : undefined
      let ans71A = this.questionProgress.find(obj => obj.questionNumber == '71A') != undefined ? this.questionProgress.find(obj => obj.questionNumber == '71A').questionAnswer : undefined
      let ans71B = this.questionProgress.find(obj => obj.questionNumber == '71B') != undefined ? this.questionProgress.find(obj => obj.questionNumber == '71B').inputFieldsArr : []
      let ans83A = this.questionProgress.find(obj => obj.questionNumber == '83A') != undefined ? this.questionProgress.find(obj => obj.questionNumber == '83A').questionAnswer : undefined
      let ans83B = this.questionProgress.find(obj => obj.questionNumber == '83B') != undefined ? this.questionProgress.find(obj => obj.questionNumber == '83B').questionAnswer : undefined
      let ans83E = this.questionProgress.find(obj => obj.questionNumber == '83E') != undefined ? this.questionProgress.find(obj => obj.questionNumber == '83E').questionAnswer : undefined
      let ans83F = this.calculationData.find(obj => obj.key == '83F') != undefined ? this.calculationData.find(obj => obj.key == '83F').answerData : undefined
      let ans83G = this.calculationData.find(obj => obj.key == '83G') != undefined ? this.calculationData.find(obj => obj.key == '83G').answerData : undefined
      let ans83J = this.questionProgress.find(obj => obj.questionNumber == '83J') != undefined ? this.questionProgress.find(obj => obj.questionNumber == '83J').questionAnswer : undefined
      let ans84A = this.questionProgress.find(obj => obj.questionNumber == '84A') != undefined ? this.questionProgress.find(obj => obj.questionNumber == '84A').questionAnswer : undefined
      let ans84B = this.calculationData.find(obj => obj.key == '84B') != undefined ? this.calculationData.find(obj => obj.key == '84B').answerData : undefined
      let ans84D = this.questionProgress.find(obj => obj.questionNumber == '84D') != undefined ? this.questionProgress.find(obj => obj.questionNumber == '84D').questionAnswer : undefined
      let ans84F = this.calculationData.find(obj => obj.key == '84F') != undefined ? this.calculationData.find(obj => obj.key == '84F').answerData : undefined
      let ans84G = this.questionProgress.find(obj => obj.questionNumber == '84G') != undefined ? this.questionProgress.find(obj => obj.questionNumber == '84G').questionAnswer : undefined
      let ans84J = this.questionProgress.find(obj => obj.questionNumber == '84J') != undefined ? this.questionProgress.find(obj => obj.questionNumber == '84J').questionAnswer : undefined
      let ans84K = this.questionProgress.find(obj => obj.questionNumber == '84K') != undefined ? this.questionProgress.find(obj => obj.questionNumber == '84K').questionAnswer : undefined
      let ans84N = this.questionProgress.find(obj => obj.questionNumber == '84N') != undefined ? this.questionProgress.find(obj => obj.questionNumber == '84N').questionAnswer : undefined
      let ans85 = this.calculationData.find(obj => obj.key == '85') != undefined ? this.calculationData.find(obj => obj.key == '85').answerData : undefined
      let ans86B = this.questionProgress.find(obj => obj.questionNumber == '86B') != undefined ? this.questionProgress.find(obj => obj.questionNumber == '86B').questionAnswer : undefined
      let ans87A = this.questionProgress.find(obj => obj.questionNumber == '87A') != undefined ? this.questionProgress.find(obj => obj.questionNumber == '87A').questionAnswer : undefined
      let ans87B = this.questionProgress.find(obj => obj.questionNumber == '87B') != undefined ? this.questionProgress.find(obj => obj.questionNumber == '87B').questionAnswer : undefined
      let ans87C = this.calculationData.find(obj => obj.key == '87C') != undefined ? this.calculationData.find(obj => obj.key == '87C').answerData : undefined
      let ans87D = this.calculationData.find(obj => obj.key == '87D') != undefined ? this.calculationData.find(obj => obj.key == '87D').answerData : undefined
      let ans87E = this.calculationData.find(obj => obj.key == '87E') != undefined ? this.calculationData.find(obj => obj.key == '87E').answerData : undefined
      let ans88 = this.questionProgress.find(obj => obj.questionNumber == '88') != undefined ? this.questionProgress.find(obj => obj.questionNumber == '88').extraInfo : undefined
      let ans90 = this.questionProgress.find(obj => obj.questionNumber == '90') != undefined ? this.questionProgress.find(obj => obj.questionNumber == '90').extraInfo : undefined
      let ans91 = this.questionProgress.find(obj => obj.questionNumber == '91') != undefined ? this.questionProgress.find(obj => obj.questionNumber == '91').extraInfo : undefined
      let ans92 = this.questionProgress.find(obj => obj.questionNumber == '92') != undefined ? this.questionProgress.find(obj => obj.questionNumber == '92').extraInfo : undefined
      let ans93 = this.questionProgress.find(obj => obj.questionNumber == '93') != undefined ? this.questionProgress.find(obj => obj.questionNumber == '93').extraInfo : undefined
      let ans94 = this.questionProgress.find(obj => obj.questionNumber == '94') != undefined ? this.questionProgress.find(obj => obj.questionNumber == '94').extraInfo : undefined
      let ans95 = this.questionProgress.find(obj => obj.questionNumber == '95') != undefined ? this.questionProgress.find(obj => obj.questionNumber == '95').extraInfo : undefined
      let ans96 = this.questionProgress.find(obj => obj.questionNumber == '96') != undefined ? this.questionProgress.find(obj => obj.questionNumber == '96').extraInfo : undefined
      let ans97 = this.questionProgress.find(obj => obj.questionNumber == '97') != undefined ? this.questionProgress.find(obj => obj.questionNumber == '97').extraInfo : undefined
      let ans98B = this.questionProgress.find(obj => obj.questionNumber == '98B') != undefined ? this.questionProgress.find(obj => obj.questionNumber == '98B').extraInfo : undefined
      let ans100 = this.questionProgress.find(obj => obj.questionNumber == '100') != undefined ? this.questionProgress.find(obj => obj.questionNumber == '100').extraInfo : undefined
      let ans101 = this.questionProgress.find(obj => obj.questionNumber == '101') != undefined ? this.questionProgress.find(obj => obj.questionNumber == '101').questionAnswer : undefined
      let ans102 = this.questionProgress.find(obj => obj.questionNumber == '102') != undefined ? this.questionProgress.find(obj => obj.questionNumber == '102').extraInfo : undefined
      let ans113A = this.questionProgress.find(obj => obj.questionNumber == '113A') != undefined ? this.questionProgress.find(obj => obj.questionNumber == '113A').questionAnswer : undefined
      let ans113B = this.questionProgress.find(obj => obj.questionNumber == '113B') != undefined ? this.questionProgress.find(obj => obj.questionNumber == '113B').questionAnswer : undefined
      let ans113C = this.questionProgress.find(obj => obj.questionNumber == '113C') != undefined ? this.questionProgress.find(obj => obj.questionNumber == '113C').questionAnswer : undefined
      let ans113D = this.questionProgress.find(obj => obj.questionNumber == '113D') != undefined ? this.questionProgress.find(obj => obj.questionNumber == '113D').questionAnswer : undefined
      let ans113E = this.questionProgress.find(obj => obj.questionNumber == '113E') != undefined ? this.questionProgress.find(obj => obj.questionNumber == '113E').questionAnswer : undefined
      let ans113F = this.calculationData.find(obj => obj.key == '113F') != undefined ? this.calculationData.find(obj => obj.key == '113F').answerData : undefined
      let ans113J = this.questionProgress.find(obj => obj.questionNumber == '113J') != undefined ? this.questionProgress.find(obj => obj.questionNumber == '113J').questionAnswer : undefined
      let ans113G = this.calculationData.find(obj => obj.key == '113G') != undefined ? this.calculationData.find(obj => obj.key == '113G').answerData : undefined
      let ans114A = this.questionProgress.find(obj => obj.questionNumber == '114A') != undefined ? this.questionProgress.find(obj => obj.questionNumber == '114A').questionAnswer : undefined
      let ans114D = this.questionProgress.find(obj => obj.questionNumber == '114D') != undefined ? this.questionProgress.find(obj => obj.questionNumber == '114D').questionAnswer : undefined
      let ans114G = this.questionProgress.find(obj => obj.questionNumber == '114G') != undefined ? this.questionProgress.find(obj => obj.questionNumber == '114G').questionAnswer : undefined
      let ans114J = this.questionProgress.find(obj => obj.questionNumber == '114J') != undefined ? this.questionProgress.find(obj => obj.questionNumber == '114J').questionAnswer : undefined
      let ans114N = this.questionProgress.find(obj => obj.questionNumber == '114N') != undefined ? this.questionProgress.find(obj => obj.questionNumber == '114N').questionAnswer : undefined
      let ans115 = this.calculationData.find(obj => obj.key == '115') != undefined ? this.calculationData.find(obj => obj.key == '115').answerData : undefined
      let ans116B = this.questionProgress.find(obj => obj.questionNumber == '116B') != undefined ? this.questionProgress.find(obj => obj.questionNumber == '116B').questionAnswer : undefined
      let ans117A = this.calculationData.find(obj => obj.key == '117A') != undefined ? this.calculationData.find(obj => obj.key == '117A').answerData : undefined
      let ans117C = this.calculationData.find(obj => obj.key == '117C') != undefined ? this.calculationData.find(obj => obj.key == '117C').answerData : undefined
      let ans117D = this.calculationData.find(obj => obj.key == '117D') != undefined ? this.calculationData.find(obj => obj.key == '117D').answerData : undefined
      let ans117E = this.calculationData.find(obj => obj.key == '117E') != undefined ? this.calculationData.find(obj => obj.key == '117E').answerData : undefined
      let ans118 = this.questionProgress.find(obj => obj.questionNumber == '118') != undefined ? this.questionProgress.find(obj => obj.questionNumber == '118').extraInfo : undefined
      let ans120 = this.questionProgress.find(obj => obj.questionNumber == '120') != undefined ? this.questionProgress.find(obj => obj.questionNumber == '120').extraInfo : undefined
      let ans121 = this.questionProgress.find(obj => obj.questionNumber == '121') != undefined ? this.questionProgress.find(obj => obj.questionNumber == '121').extraInfo : undefined
      let ans122 = this.questionProgress.find(obj => obj.questionNumber == '122') != undefined ? this.questionProgress.find(obj => obj.questionNumber == '122').extraInfo : undefined
      let ans123 = this.questionProgress.find(obj => obj.questionNumber == '123') != undefined ? this.questionProgress.find(obj => obj.questionNumber == '123').extraInfo : undefined
      let ans124 = this.questionProgress.find(obj => obj.questionNumber == '124') != undefined ? this.questionProgress.find(obj => obj.questionNumber == '124').extraInfo : undefined
      let ans125 = this.questionProgress.find(obj => obj.questionNumber == '125') != undefined ? this.questionProgress.find(obj => obj.questionNumber == '125').extraInfo : undefined
      let ans126 = this.questionProgress.find(obj => obj.questionNumber == '126') != undefined ? this.questionProgress.find(obj => obj.questionNumber == '126').extraInfo : undefined
      let ans127 = this.questionProgress.find(obj => obj.questionNumber == '127') != undefined ? this.questionProgress.find(obj => obj.questionNumber == '127').extraInfo : undefined
      let ans128B = this.questionProgress.find(obj => obj.questionNumber == '128B') != undefined ? this.questionProgress.find(obj => obj.questionNumber == '128B').extraInfo : undefined
      let ans130 = this.questionProgress.find(obj => obj.questionNumber == '130') != undefined ? this.questionProgress.find(obj => obj.questionNumber == '130').extraInfo : undefined
      let ans131 = this.questionProgress.find(obj => obj.questionNumber == '131') != undefined ? this.questionProgress.find(obj => obj.questionNumber == '131').extraInfo : undefined
      let ans132 = this.questionProgress.find(obj => obj.questionNumber == '132') != undefined ? this.questionProgress.find(obj => obj.questionNumber == '132').extraInfo : undefined
      let ans201 = this.questionProgress.find(obj => obj.questionNumber == '201') != undefined ? this.questionProgress.find(obj => obj.questionNumber == '201').questionAnswer : undefined
      let ans202 = this.questionProgress.find(obj => obj.questionNumber == '202') != undefined ? this.questionProgress.find(obj => obj.questionNumber == '202').questionAnswer : undefined
      switch (String(answernumber)) {
        case '17':
          this.addingSimpleDataToCalculation('14', Number(ans14))
          let q17
          if (ans16 == undefined) {
            q17 = ans14
          } else {
            q17 = ans14 - Math.abs(ans16)
          }
          if (q17 > 0) {
            this.nextQuestionNumbType9 = '18'
            this.nextQuestionSubType9 = 'C'
          } else if (q17 <= 0) {
            this.nextQuestionNumbType9 = '72'
            this.nextQuestionSubType9 = false
          }
          this.addingSimpleDataToCalculation('17', q17)
          this.selectedAnswer.answerText = q17
          break;
        case '18':
          switch (ansSub) {
            case 'A':
              this.selectedAnswer.answerText = ans17
              break;
            case 'B':
              this.selectedAnswer.answerText = ans4
              break;
            case 'C':
              let q18C = ans17 - ans4
              this.addingSimpleDataToCalculation('18C', q18C)
              this.selectedAnswer.answerText = q18C
              if (q18C > 0) {
                this.nextQuestionNumbType9 = '18'
                this.nextQuestionSubType9 = "D"
              } else if (q18C <= 0) {
                this.nextQuestionNumbType9 = '73'
                this.nextQuestionSubType9 = false
              }
              break;
            case 'D':
              this.nextQuestionNumbType9 = '21'
              this.nextQuestionSubType9 = false
          }
          break;
        case '34':
          switch (ansSub) {
            case 'A':
              let q34A = ans17
              this.addingSimpleDataToCalculation('34A', q34A)
              this.selectedAnswer.answerText = ans17
              break;
            case 'B':
              let tempAns23;
              let tempAns24;
              let tempAns27;
              let tempAns30;
              let tempAns33;
              ans23 == undefined ? tempAns23 = 0 : tempAns23 = Number(ans23)
              ans24 == undefined ? tempAns24 = 0 : tempAns24 = Number(ans24)
              ans27 == undefined ? tempAns27 = 0 : tempAns27 = Number(ans27)
              ans30 == undefined ? tempAns30 = 0 : tempAns30 = Number(ans30)
              ans33 == undefined ? tempAns33 = 0 : tempAns33 = Number(ans33)
              let q34B = tempAns23 - tempAns24 - tempAns27 - tempAns30 + tempAns33
              this.addingSimpleDataToCalculation('34B', q34B)
              this.selectedAnswer.answerText = q34B
              break;
            case 'C':
              let q34C = ans2 / 100 * -ans34B
              this.addingSimpleDataToCalculation('34C', Math.round(q34C))
              this.selectedAnswer.answerText = Math.round(q34C)
              break;
            case 'D':
              let q34D = Number(ans34A) + (ans34B) + Number(ans34C)
              this.addingSimpleDataToCalculation('34D', q34D)
              this.selectedAnswer.answerText = q34D
              if (q34D > 0) {
                this.nextQuestionNumbType9 = '35'
                this.nextQuestionSubType9 = 'A'
              } else if (q34D < 0) {
                this.nextQuestionNumbType9 = '34'
                this.nextQuestionSubType9 = 'E'
              }
              break;
            case 'E':
              this.nextQuestionNumbType9 = '51'
              this.nextQuestionSubType9 = 'A'
              break;
          }
          break;
        case '35':
          switch (ansSub) {
            case 'A':
              this.selectedAnswer.answerText = ans34D
              break;
            case 'B':
              this.selectedAnswer.answerText = ans4
              break;
            case 'C':
              let q35C = ans34D - ans4
              this.addingSimpleDataToCalculation('35C', q35C)
              this.selectedAnswer.answerText = q35C
              if (q35C > 0) {
                this.nextQuestionNumbType9 = '35'
                this.nextQuestionSubType9 = 'E'
              } else if (q35C < 0) {
                this.nextQuestionNumbType9 = '35'
                this.nextQuestionSubType9 = 'D'
              }
              break;
            case 'D' || 'E':
              this.nextQuestionNumbType9 = '51'
              this.nextQuestionSubType9 = 'A'
              break;
          }
          break;
        case '51':
          switch (ansSub) {
            case 'C':
              this.selectedAnswer.answerText = ans51BcontrolCalc
              if (ans51BcontrolCalc != 0) {
                this.controlCalculation.status = true
                this.controlCalculation.next = false
              } else if (ans51BcontrolCalc == 0) {
                this.controlCalculation.status = true
                this.controlCalculation.next = true
              }
              break;
          }
          break;
        case '54':
          switch (ansSub) {
            case 'A':
              let q54A;
              if (ans53 == undefined) {
                q54A = ans51N
              } else if (ans53 != undefined) {
                q54A = ans51N - ans53
              }
              this.addingSimpleDataToCalculation('54A', q54A)
              this.selectedAnswer.answerText = q54A
              break;
            case 'B':
              this.addingSimpleDataToCalculation('54B', ans4)
              this.selectedAnswer.answerText = ans4
              break;
            case 'C':
              let q54C = ans54A - ans4
              this.addingSimpleDataToCalculation('54C', q54C)
              this.selectedAnswer.answerText = q54C
              if (ans51N < 0) {
                this.nextQuestionNumbType9 = '154'
                this.nextQuestionSubType9 = 'F'
              } else if (q54C < 0) {
                this.nextQuestionNumbType9 = '154'
                this.nextQuestionSubType9 = 'G'
              } else if (q54C > 0) {
                this.nextQuestionNumbType9 = '154'
                this.nextQuestionSubType9 = 'H'
              }
              break;
            case 'D':
              let q54D;
              let answerTextD;
              if (ans51L != '0') {
                q54D = (ans54C + Number(ans51L)) / Number(ans51L)
                q54D = q54D.toFixed(2)
                answerTextD = q54D
              } else {
                answerTextD = 'Kortlopende schulden zijn 0.'
                q54D = ans54C
              }
              this.addingSimpleDataToCalculation('54D', q54D)
              this.selectedAnswer.answerText = answerTextD
              if (q54D < 1.5) {
                this.nextQuestionNumbType9 = '154'
                this.nextQuestionSubType9 = 'I'
              } else if (q54D >= 1.5) {
                this.nextQuestionNumbType9 = '154'
                this.nextQuestionSubType9 = 'J'
              }
              break;
            case 'E':
              let q54E
              let answerText;
              if (ans51L != '0') {
                q54E = (ans54C + Number(ans51L) - Number(ans51D)) / (Number(ans51L))
                q54E = q54E.toFixed(2)
                answerText = q54E
              } else {
                answerText = 'Kortlopende schulden zijn 0.'
                q54E = (ans54C - ans51D) / 1
              }
              this.addingSimpleDataToCalculation('54E', q54E)
              this.selectedAnswer.answerText = answerText
              // this.nextQuestionNumbType9 = '55'
              // this.nextQuestionSubType9 = false
              if (ans54A < 0 || ans54C < 0) {
                this.redirectedQuestionNumb = '77'
                this.redirectedQuestionSub = ''
              } else if (ans54A >= 0 && ans54C >= 0) {
                this.redirectedQuestionNumb = '79'
                this.redirectedQuestionSub = ''
              }

              if (q54E >= 1 && ans34D > 0 && ans35C > 0 && ans51N > 0 && ans54C > 0 && ans54D >= 1.5) {
                this.nextQuestionNumbType9 = '154'
                this.nextQuestionSubType9 = 'A'
              } else if (q54E < 1) {
                this.nextQuestionNumbType9 = '154'
                this.nextQuestionSubType9 = 'B'
              } else if (q54E >= 1) {
                this.nextQuestionNumbType9 = '154'
                this.nextQuestionSubType9 = 'C'
              }
              break;
          }
          break;
        case '69':
          if (this.redirectedQuestionNumb != '77' || this.redirectedQuestionNumb != '79') {
            this.redirectedQuestionNumb = '77'
            this.redirectedQuestionSub = 'A'
          }
          this.nextQuestionNumbType9 = '71'
          this.nextQuestionSubType9 = 'A'
          break;
        case '70':
          this.redirectedQuestionNumb = '79'
          this.redirectedQuestionSub = 'A'
          this.nextQuestionNumbType9 = '71'
          this.nextQuestionSubType9 = 'A'
          break;
        case '71':
          switch (ansSub) {
            case 'A':
                this.useCustomNumber = false
                this.nextQuestionNumbType9 = this.nextID.nextID
                this.nextQuestionSubType9 = false
            break;
          }
          break;
        case '83':
          switch (ansSub) {
            case 'B':
              this.selectedAnswer.answerText = ans34D
              break;
            case 'F':
              let q83F
              let percentage = ans2 / 100
              if (ans83E == undefined) {
                q83F = (+ans83A) * percentage
              } else if (true) {
                q83F = (+ans83A + Number(ans83E)) * percentage
              }
              this.addingSimpleDataToCalculation('83F', Math.round(q83F))
              //
              this.selectedAnswer.answerText = Math.round(q83F)
              // this.processQuestion(false, false, true, "83", "G", this.selectedAnswer, this.questionResult, false, true)
              break;
            case 'G':
              let q83G
              if (ans83E == undefined) {
                q83G = +Number(ans83A) + ans83B - ans83F
              } else if (true) {
                q83G = +Number(ans83A) + ans83B + Number(ans83E) - ans83F
              }
              this.addingSimpleDataToCalculation('83G', q83G)
              this.selectedAnswer.answerText = q83G
              if (q83G <= 0) {
                this.nextQuestionNumbType9 = '83'
                this.nextQuestionSubType9 = 'K'
              } else {
                this.nextQuestionNumbType9 = '83'
                this.nextQuestionSubType9 = 'J'
              }
              break;
            case 'J':
              let q83J = ans83G - ans4
              this.selectedAnswer.answerText = q83J
              if (q83J <= 0) {
                this.nextQuestionNumbType9 = '83'
                this.nextQuestionSubType9 = 'L'
              } else {
                this.nextQuestionNumbType9 = '83'
                this.nextQuestionSubType9 = 'M'
              }
              break;
          }
          break;
        case '84':
          switch (ansSub) {
            case 'B':
              ans51D != undefined ? ans51D = Number(ans51D) : undefined
              this.selectedAnswer.answerText = ans51D
              break;
            case 'C':
              let q84C = ans84A - ans51D
              this.addingSimpleDataToCalculation('84C', q84C)
              this.selectedAnswer.answerText = q84C
              break;
            case 'E':
              ans51E != undefined ? ans51E = Number(ans51E) : undefined
              this.selectedAnswer.answerText = ans51E
              break;
            case 'F':
              let q84F = ans84D - ans51E
              q84F != undefined ? q84F = Number(q84F) : undefined
              this.addingSimpleDataToCalculation('84F', q84F)
              this.selectedAnswer.answerText = q84F
              break;
            case 'G':
              ans84G != undefined ? ans84G = parseFloat(ans84G) : undefined
              this.selectedAnswer.answerText = ans84G
              break;
            case 'H':
              this.selectedAnswer.answerText = ans51F
              break;
            case 'I':
              let q84I = ans84G - ans51F
              q84I != undefined ? q84I = parseFloat(q84I) : undefined
              this.addingSimpleDataToCalculation('84I', q84I)
              this.selectedAnswer.answerText = q84I
              break;
            case 'K':
              this.selectedAnswer.answerText = ans51L
              break;
            case 'L':
              let q84L = ans84J - ans84K
              q84L != undefined ? q84L = parseFloat(q84L) : undefined
              this.addingSimpleDataToCalculation('84L', q84L)
              this.selectedAnswer.answerText = q84L
              break;
          }
          break;
        case '85':
          ans84A != undefined ? ans84A = parseFloat(ans84A) : undefined
          ans84D != undefined ? ans84D = parseFloat(ans84D) : undefined
          ans84G != undefined ? ans84G = parseFloat(ans84G) : undefined
          ans84J != undefined ? ans84J = parseFloat(ans84J) : undefined
          let q85 = ans84A + ans84D + ans84G - ans84J
          this.addingSimpleDataToCalculation('85', q85)
          this.selectedAnswer.answerText = q85
          if (ans84N == 'Nee' && q85 > 0) {
            this.nextQuestionNumbType9 = '108'
            this.nextQuestionSubType9 = 'A'
          } else if (ans84N == 'Nee' && q85 < 0) {
            this.nextQuestionNumbType9 = '107'
            this.nextQuestionSubType9 = 'A'
          } else {
            this.nextQuestionNumbType9 = '86'
            this.nextQuestionSubType9 = 'A'
          }
          break;
        case '87':
          switch (ansSub) {
            case 'A':
              let q87A
              if (ans86B == undefined) {
                q87A = ans85
              } else {
                q87A = ans85 - ans86B
              }
              q87A != undefined ? q87A = parseFloat(q87A) : undefined
              this.addingSimpleDataToCalculation('87A', q87A)
              this.selectedAnswer.answerText = q87A
              break;
            case 'B':
              this.selectedAnswer.answerText = ans4
              break;
            case 'C':
              let q87C = ans87A - ans4
              this.addingSimpleDataToCalculation('87C', q87C)
              this.selectedAnswer.answerText = q87C
              if (ans85 < 0) {
                this.nextQuestionNumbType9 = '87'
                this.nextQuestionSubType9 = 'F'
              } else if (q87C < 0) {
                this.nextQuestionNumbType9 = '87'
                this.nextQuestionSubType9 = 'G'
              } else if (q87C > 0) {
                this.nextQuestionNumbType9 = '87'
                this.nextQuestionSubType9 = 'H'
              }
              break;
            case 'D':
              let q87D
              let answerTextD;
              if (ans84J != '0') {
                q87D = (ans87C + Number(ans84J)) / Number(ans84J)
                q87D = q87D.toFixed(2)
                answerTextD = q87D
              } else {
                answerTextD = 'Kortlopende schulden zijn 0.'
                q87D = ans87C
              }
              this.selectedAnswer.answerText = answerTextD
              this.addingSimpleDataToCalculation('87D', q87D)

              if (q87D < 1.5) {
                this.nextQuestionNumbType9 = '87'
                this.nextQuestionSubType9 = 'I'
              } else if (q87D >= 1.5) {
                this.nextQuestionNumbType9 = '87'
                this.nextQuestionSubType9 = 'J'
              }
              break;
            case 'E':
              let q87E;
              let answerText;
              if (ans84J != '0') {
                q87E = (ans87C + Number(ans84J) - Number(ans84A)) / (Number(ans84J))
                q87E = q87E.toFixed(2);
                answerText = q87E;
              } else {
                answerText = 'Kortlopende schulden zijn 0.'
                q87E = (ans87C - ans84A) / 1
              }
              this.addingSimpleDataToCalculation('87E', q87E)
              this.selectedAnswer.answerText = answerText
              if (q87E < 1) {
                this.nextQuestionNumbType9 = '187'
                this.nextQuestionSubType9 = 'A'
              } else if (q87E >= 1 && ans85 > 0 && ans87C > 0 && ans83G > 0 && ans83J > 0 && ans87D >= 1.5) {
                this.nextQuestionNumbType9 = '187'
                this.nextQuestionSubType9 = 'B'
              } else if (q87E >= 1) {
                this.nextQuestionNumbType9 = '187'
                this.nextQuestionSubType9 = 'C'
              }

              break;
          }
          break;
        case '98':
          switch (ansSub) {
            case 'A':
              if (ans71B.length > 0) {
                this.inputFields = ans71B
              } else {
                this.inputFields = ["Geen aspecten ingevuld"]
              }
              break;
          }
          break;
        case '113':
          switch (ansSub) {
            case 'B':
              this.selectedAnswer.answerText = ans83G
              break;
            case 'F':
              ans113E == undefined ? ans113E = 0 : ans113E = Number(ans113E)
              ans113A == undefined ? ans113A = 0 : ans113A = Number(ans113A)
              let q113F = (ans113A + ans113E) * (ans2 / 100)
              this.addingSimpleDataToCalculation('113F', Math.round(q113F))
              this.selectedAnswer.answerText = Math.round(q113F)
              break;
            case 'G':
              ans113A == undefined ? ans113A = 0 : ans113A = Number(ans113A)
              ans113B == undefined ? ans113B = 0 : ans113B = Number(ans113B)
              ans113E == undefined ? ans113E = 0 : ans113E = Number(ans113E)
              ans113F == undefined ? ans113F = 0 : ans113F = Number(ans113F)

              let q113G = ans113A + ans113B + ans113E - ans113F
              this.addingSimpleDataToCalculation('113G', q113G)
              this.selectedAnswer.answerText = q113G
              if (q113G <= 0) {
                this.nextQuestionNumbType9 = '213'
                this.nextQuestionSubType9 = 'G'
              } else if (q113G >= 0) {
                this.nextQuestionNumbType9 = '113'
                this.nextQuestionSubType9 = 'H'
              }
              break;
            case 'H':
              this.selectedAnswer.answerText = ans113G
              break;
            case 'I':
              this.selectedAnswer.answerText = Number(ans4)
              break;
            case 'J':
              let q113J = ans113G - Number(ans4)
              if (q113J <= 0) {
                this.nextQuestionNumbType9 = '213'
                this.nextQuestionSubType9 = 'K'
              } else if (q113J >= 0) {
                this.nextQuestionNumbType9 = '213'
                this.nextQuestionSubType9 = 'L'
              }
              this.selectedAnswer.answerText = q113J
              break;
          }
          break;
        case '114':
          switch (ansSub) {
            case 'B':
              this.selectedAnswer.answerText = ans84A
              break;
            case 'C':
              let q114C = ans114A - ans84A
              this.addingSimpleDataToCalculation('114C', q114C)
              this.selectedAnswer.answerText = q114C
              break;
            case 'E':
              this.selectedAnswer.answerText = ans84D
              break;
            case 'F':
              let q114F = ans114D - ans84D
              this.addingSimpleDataToCalculation('114F', q114F)
              this.selectedAnswer.answerText = q114F
              break;
            case 'H':
              this.selectedAnswer.answerText = ans84G
              break;
            case 'I':
              let q114I = ans114G - ans84G
              this.addingSimpleDataToCalculation('114I', q114I)
              this.selectedAnswer.answerText = q114I
              break;
            case 'K':
              this.selectedAnswer.answerText = ans84J
              break;
            case 'L':
              let q114L = ans114J - ans84J
              this.addingSimpleDataToCalculation('114L', q114L)
              this.selectedAnswer.answerText = q114L
              break;
          }
          break;
        case '115':
          let q115 = Number(ans114A) + Number(ans114D) + Number(ans114G) - Number(ans114J)
          this.addingSimpleDataToCalculation('115', q115)
          this.selectedAnswer.answerText = q115
          if (ans114N == 'Nee' && q85 > 0) {
            this.nextQuestionNumbType9 = '138'
            this.nextQuestionSubType9 = 'A'
          } else if (ans114N == 'Nee' && q85 < 0) {
            this.nextQuestionNumbType9 = '138'
            this.nextQuestionSubType9 = 'A'
          } else {
            this.nextQuestionNumbType9 = '116'
            this.nextQuestionSubType9 = 'A'
          }
          break;
        case '117':
          switch (ansSub) {
            case 'A':
              let q117A;
              if (ans116B == undefined) {
                q117A = ans115
              } else if (ans116B != undefined) {
                q117A = ans115 - ans116B
              }
              this.addingSimpleDataToCalculation('117A', q117A)
              this.selectedAnswer.answerText = q117A
              break;
            case 'B':
              this.addingSimpleDataToCalculation('117B', ans4)
              this.selectedAnswer.answerText = ans4
              break;
            case 'C':
              let q117C = ans117A - ans4
              this.addingSimpleDataToCalculation('117C', q117C)
              this.selectedAnswer.answerText = q117C
              if (ans115 < 0) {
                this.nextQuestionNumbType9 = '217'
                this.nextQuestionSubType9 = 'F'
              } else if (q117C < 0) {
                this.nextQuestionNumbType9 = '217'
                this.nextQuestionSubType9 = 'G'
              } else if (q117C > 0) {
                this.nextQuestionNumbType9 = '217'
                this.nextQuestionSubType9 = 'H'
              }
              break;
            case 'D':
              let q117D;
              let answerTextD;
              if (ans114J != '0') {
                q117D = (Number(ans117C) + Number(ans114J)) / Number(ans114J)
                q117D = q117D.toFixed(2)
                answerTextD = q117D
              } else {
                answerTextD = 'Kortlopende schulden zijn 0.'
                q117D = ans117C
              }
              this.addingSimpleDataToCalculation('117D', q117D)
              this.selectedAnswer.answerText = answerTextD
              if (q117D < 1.5) {
                this.nextQuestionNumbType9 = '217'
                this.nextQuestionSubType9 = 'I'
              } else if (q117D >= 1.5) {
                this.nextQuestionNumbType9 = '217'
                this.nextQuestionSubType9 = 'J'
              }
              break;
            case 'E':
              let q117E;
              let answerText;
              if (ans114J != '0') {
                q117E = (ans117C + Number(ans114J) - Number(ans114A)) / (Number(ans114J))
                q117E = q117E.toFixed(2)
                answerText = q117E
              } else {
                answerText = 'Kortlopende schulden zijn 0.'
                q117E = (ans117C + Number(ans114J) - ans114A) / 1
              }

              this.addingSimpleDataToCalculation('117E', q117E)
              this.selectedAnswer.answerText = answerText
              if (q117E < 1) {
                this.nextQuestionNumbType9 = '217'
                this.nextQuestionSubType9 = 'K'
              } else if (q117E >= 1 && ans115 > 0 && ans117C > 0 && ans113G > 0 && ans113J > 0 && ans117D >= 1.5) {
                this.nextQuestionNumbType9 = '217'
                this.nextQuestionSubType9 = 'L'
              } else if (q117E >= 1) {
                this.nextQuestionNumbType9 = '217'
                this.nextQuestionSubType9 = 'M'
              }
              break;
          }
          break;
        case '128':
          switch (ansSub) {
            case 'A':
              if (ans71B.length > 0) {
                this.inputFields = ans71B
              } else {
                this.inputFields = ["Geen aspecten ingevuld"]
              }
              break;
          }
          break;
        case '154':
          switch (ansSub) {
            case 'A':
              this.nextQuestionNumbType9 = '55'
              this.nextQuestionSubType9 = false
              break
            case 'B':
            case 'C':
              if (this.redirectedQuestionNumb == '77') {
                this.nextQuestionNumbType9 = '69'
                this.nextQuestionSubType9 = false
              } else if (this.redirectedQuestionNumb == '79') {
                this.nextQuestionNumbType9 = '70'
                this.nextQuestionSubType9 = false
              }
              break
          }
          break;
        case '187':
          switch (ansSub) {
            case 'A':
              if (ans87A < 0 || ans87A < 0) {
                this.nextQuestionNumbType9 = '105'
                this.nextQuestionSubType9 = false
              } else if (ans87A > 0 && ans87C > 0 && ans83G > 0 && ans83J > 0 && ans87D >= 1.5 && ans87E >= 1) {
                this.nextQuestionNumbType9 = '88'
                this.nextQuestionSubType9 = false
              } else {
                this.nextQuestionNumbType9 = '105'
                this.nextQuestionSubType9 = false
              }
              break;
            case 'B':
              if (ans87A < 0 || ans87C < 0) {
                this.nextQuestionNumbType9 = '105'
                this.nextQuestionSubType9 = false
              } else if (ans87A > 0 && ans87C > 0 && ans83G > 0 && ans83J > 0 && ans87D >= 1.5 && ans87E >= 1) {
                this.nextQuestionNumbType9 = '88'
                this.nextQuestionSubType9 = false
              } else {
                this.nextQuestionNumbType9 = '105'
                this.nextQuestionSubType9 = false
              }
              break;
            case 'C':
              if (ans87A < 0 || ans87C < 0) {
                this.nextQuestionNumbType9 = '105'
                this.nextQuestionSubType9 = false
              } else if (ans87A > 0 && ans87C > 0 && ans83G > 0 && ans83J > 0 && ans87D >= 1.5 && ans87E >= 1) {
                this.nextQuestionNumbType9 = '88'
                this.nextQuestionSubType9 = false
              } else {
                this.nextQuestionNumbType9 = '105'
                this.nextQuestionSubType9 = false
              }
              break;
          }
          break;
        case '202':
          this.inputFields = ['']
          break;
        case '217':
          switch (ansSub) {
            case 'K':
              if (ans117A < 0 || ans117C < 0) {
                this.futureResultNumber = '137'
                this.nextQuestionNumbType9 = '118'
                this.nextQuestionSubType9 = false
              } else if (ans117A > 0 && ans117C > 0 && ans113G > 0 && ans113J > 0 && ans117D >= 1.5 && ans117E >= 1) {
                this.nextQuestionNumbType9 = '118'
                this.nextQuestionSubType9 = false
              } else {
                this.futureResultNumber = '138'
                this.nextQuestionNumbType9 = '118'
                this.nextQuestionSubType9 = false
              }
              break;
            case 'L':
              if (ans117A < 0 || ans117C < 0) {
                this.futureResultNumber = '137'
                this.nextQuestionNumbType9 = '118'
                this.nextQuestionSubType9 = false
              } else if (ans117A > 0 && ans117C > 0 && ans113G > 0 && ans113J > 0 && ans117D >= 1.5 && ans117E >= 1) {
                this.nextQuestionNumbType9 = '118'
                this.nextQuestionSubType9 = false
              } else {
                this.futureResultNumber = '138'
                this.nextQuestionNumbType9 = '118'
                this.nextQuestionSubType9 = false
              }
              break;
            case 'M':
              if (ans117A < 0 || ans117C < 0) {
                this.futureResultNumber = '137'
                this.nextQuestionNumbType9 = '118'
                this.nextQuestionSubType9 = false
              } else if (ans117A > 0 && ans117C > 0 && ans113G > 0 && ans113J > 0 && ans117D >= 1.5 && ans117E >= 1) {
                this.nextQuestionNumbType9 = '118'
                this.nextQuestionSubType9 = false
              } else {
                this.futureResultNumber = '138'
                this.nextQuestionNumbType9 = '118'
                this.nextQuestionSubType9 = false
              }
              break;
          }
          break;
      }
    },

    addingSimpleDataToCalculation(key, data) {
      const item = this.calculationData.find(i => i.key == key)
      if (!item) {
        this.calculationData.push({key: key, answerData: data})
      } else {
        item.answerData = data
      }
    },


    async activateMonitor(remove_current_user) {
      const storageUser = localStorage.getItem('user');
      const id = this.$route.params.id
      let status;
      if(remove_current_user){
        if(this.firstQuestion){
          status = 'nieuw';
        }
        else{
          status = 'lopend';
        }
      }
      else{
        status = 'in behandeling door'
      }
      const obj = {
        remove_current_user: remove_current_user,
        test_current_user_id: JSON.parse(storageUser).data.userId,
        test_status: status,
        test_unique_id: id,
      }
      const requestOptions = {
        method: "PUT",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify(obj),
      };
      console.log(requestOptions)
      const response = await fetch("https://nodejs.imaginecreativeagency.nl/dividenduitkering-live/tests/index.php", requestOptions);
      if (response) {
        return true;
      }
      else{
        return false;
      }
    }

  },

  async beforeMount() {
    const id = this.$route.params.id
    if (!id) {
      this.$router.push("/vragenlijst/error/404");
      return;
    }
    const obj = {
      selectAll: "true",
      from: "drv_tests AS test",
      where: {
        st1: "test_unique_id = '" + id + "'",
      },
      join: {
        st1: 'INNER JOIN drv_users AS user ON test.user_id = user.user_id '
      },
      testQuery: "false",
    }
    const requestOptions = {
      method: "POST",
      headers: {"Content-Type": "application/json"},
      body: JSON.stringify(obj),
    };
    try {
      const response = await fetch("https://nodejs.imaginecreativeagency.nl/dividenduitkering-live/dynamicGet/index.php", requestOptions);
      const json = await response.json();
      if (json.data) {
        this.currentTest = json.data
        const storage = localStorage.getItem('user')
        if(storage){
          if(this.currentTest[0].test_current_user_id){
            if(this.currentTest[0].test_current_user_id !== JSON.parse(storage).data.userId || this.currentTest[0].user_id !== JSON.parse(storage).data.userId){
              this.notAllowedToEnterTest = true
              this.$router.push("/overzicht");
            }
          }
          else{
            if(this.currentTest[0].user_id !== JSON.parse(storage).data.userId && !this.isUserExclusive){
              this.notAllowedToEnterTest = true
              this.$router.push("/overzicht");
            }
          }
        }
        const testJSON = JSON.parse(json.data[0].test_json)
        this.calculationData = JSON.parse(testJSON.calculationData)
        this.questionProgress = JSON.parse(testJSON.questionProgress)
        // this.calculationData = dummyCalc
        // this.questionProgress = dummyProg
        if (this.questionProgress.length > 0) {
          const params = this.questionProgress[this.questionProgress.length - 1].params;
          if (params) {
            if (params.questionResult?.questionNumber >= 140 || params.questionID == 201) {
              this.activePartTwo = true;
            }
            await this.processQuestion(true, params.useCalculation, params.customQNumber,
                params.questionID, params.subID
                , params.answerData, params.questionResult, params.result)
            this.scrollToBottom();
            this.activateMonitor();
          } else {
            this.loadingError = true
          }
        }
        else {
          this.firstQuestion = true
          await this.processQuestion(true, false, true, '1', '', null, null, false)
          this.activateMonitor();
        }
      } else {
        this.$router.push("/vragenlijst/error/404");
      }
    } catch (error) {
    }

  },


  mounted() {
    window.addEventListener('beforeunload', this.showWindow.bind(this));
  },

}
</script>

<style>

.button-container{
  position: absolute;
  right: 0;
  top: 16px;
}

.du-question-title .d-inline-block span, .du-question-cta p {
  color: #032B28;
}

.questionNumber {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.container-annotation-title {
  position: absolute;
  top: -18px;
  left: -6px;
  right: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.question-buttons-container{
  position:absolute;
  left: 0;
  right: 0;
  width: 100%;
}

.h-89{
  min-height: 89%!important;
}

.height{
  height: calc(100vh - (80px + 70px + 57px)) !important;
  min-height: calc(100vh - (80px + 70px + 57px)) !important;
}
</style>
